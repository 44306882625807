import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
  FetchOwnerHorsesDropDownthunk,
} from "../Redux/OwnerProfile/OwnerProfileAction";
import {
  FetchApproveRejectRequestCoveringCertificatethunk,
  FetchCancelRequestthunk,
  FetchCreateEmbryoOocyteTransferPermitServiceRequestthunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
  FetchUpdateEmbryoOocyteTransferPermitServiceRequestthunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import {
  resetGetServicesRequestByIdData,
  resetToasterMessageServicesRequests,
} from "../Redux/ServicesRequests/ServicesRequestsSlice";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import AsyncSelect from "react-select/async";
import axiosInstance from "../axios/axios";
import Popup from "reactjs-popup";
import dayjs from "dayjs";

export default function RequestEmbryoTransferRightPurchaceAgreement() {
  //selector
  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const { ownerHorsesDropDownData } = useSelector(
    (state) => state.ownerProfileSlice
  );

  const {
    toasterMessageServicesRequests,
    servicesRequestsLoading,
    getServicesRequestByIdData,
    getServiceDefinitionByIdData,
  } = useSelector((state) => state.servicesRequestsSlice);

  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
  const subOwnerProfilesByIdData = userProfile?.subAccounts ?? [];

  const dispatch = useDispatch();

  const { translate } = useTranslate();

  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const serviceId = searchParams.has("serviceId")
    ? searchParams.get("serviceId")
    : null;

  const id = searchParams.has("id") ? searchParams.get("id") : null;

  const action = searchParams.has("action") ? searchParams.get("action") : null;

  //state
  const [formModel, setFormModel] = useState({
    id: "",
    coveringCertificate: "",
    coveringCertificateDrop: {
      label: translate("web.coveringCertificate", "Covering Certificate"),
      value: null,
    },
    mareHorse: "",
    stallionHorse: "",
    mareOwner: "",
    stallionOwner: "",
    breeder: "",
    coveringDate: "",

    buyer: "",
    buyerDrop: {
      label: translate("web.owner", "owner"),
      value: null,
    },

    phone: "",
    email: "",
    idNumber: "",

    agreementDate: null,

    eventName: "",
    comment: "",
    buyerComment: "",
    referenceNumber: "",
    requestsType: "",
    paymentStatus: "",
    embryoTransferRightPurchaceAgreementId: null,
  });

  const [errorCoveringCertificate, setErrorCoveringCertificate] = useState("none");

  const [errorBuyer, setErrorBuyer] = useState("none");

  const [checkedItems, setCheckedItems] = useState({
    option1: false,
  });

  const [profile, setProfile] = useState({
    label:
      !!userProfile &&
      userProfile?.ownerRegisterNo +
      " - " +
      userProfile[`fullName${currentLanguage === "en" ? "" : "Ar"}`],
    value: localStorage.getItem("EAHSUserID"),
  });
  const subAccountId = localStorage.getItem("currentSubAccountId");

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  const [timeoutId, setTimeoutId] = useState(0);

  const [inputValue, setInputValue] = useState("");

  const [openPopup, setOpenPopup] = useState(false);

  const [openPopupCancel, setOpenPopupCancel] = useState(false);

  const _status = useRef("");

  const [errorAgreementDate, setErrorAgreementDate] = useState("none");

  //function
  const handleFormChange = (e, input) => {
    if (input === "coveringCertificate") {
      let coveringCertificate = ownerHorsesDropDownData.filter((item) => item.id == e.value);

      setFormModel({
        ...formModel,
        coveringCertificate: e.value,
        coveringCertificateDrop: e,
        mareHorse: coveringCertificate[0]?.mareHorse ?? "",
        stallionHorse: coveringCertificate[0]?.stallionHorse ?? "",
        mareOwner: coveringCertificate[0]?.mareOwner ?? "",
        stallionOwner: coveringCertificate[0]?.stallionOwner ?? "",
        coveringDate: coveringCertificate[0]?.coveringDate ?? "",
        breeder: coveringCertificate[0]?.breeder ?? "",
      });
    } else if (input === "buyer") {
      if (e?.value != null && e?.value != undefined) {
        setFormModel({
          ...formModel,
          buyer: e.value,
          buyerDrop: e,
          email: e.details.email,
          phone: e.details.phone,
          idNumber: e.details.idNumber,
        });
      }
    } else {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.value,
      });
    }
  };

  const validInputs = () => {
    let valid = true;

    if (
      formModel.coveringCertificate === ""
    ) {
      setErrorCoveringCertificate("");
      valid = false;
    }
    else {
      setErrorCoveringCertificate("none");
    }

    if (
      formModel.buyer === ""
    ) {
      setErrorBuyer("");
      valid = false;
    }
    else {
      setErrorBuyer("none");
    }

    if (
      formModel.agreementDate === null
    ) {
      setErrorAgreementDate("");
      valid = false;
    }
    else {
      setErrorAgreementDate("none");
    }

    //terms
    if (
      !checkedItems.option1
    ) {
      toast.error(
        translate("web.pleaseCheckAllTerms", "Please Check All Terms")
      );
      valid = false;
    }

    return valid;
  }

  const handelFormSubmit = (status) => {
    if (
      validInputs()
    ) {
      let params = {
        id: id,
        serviceDefinitionId: serviceId,
        status: status,
        eventName: formModel.eventName,
        coveringCertificateId: formModel.coveringCertificate,
        buyerId: formModel.buyer,
        agreementDate: formModel.agreementDate,
        ownerId: getUserID(),
        createdBy: localStorage.getItem("EAHSUserID"),
      };

      if (action == null) {
        dispatch(FetchCreateEmbryoOocyteTransferPermitServiceRequestthunk(params));
      } else if (action === "edit" || formModel.eventName === "SentBack") {
        dispatch(FetchUpdateEmbryoOocyteTransferPermitServiceRequestthunk(params));
      }
    }
  };

  const handelSelectedAccountChange = async (element) => {
    let params = {
      ownerId: element.value,
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    setProfile(element);

    setFormModel({
      ...formModel,
      horse: "",
      horseDrop: {
        label: translate("web.horse", "Horse"),
        value: null,
      },
      gender: "",
      color: "",
      birthDate: "",
      sire: "",
      dam: "",
      origin: "",
      breeder: "",
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
  };

  function CheckIfAgentHasPermission() {
    const AgentHasPermission = userProfile.subAccounts
      .filter((x) => x.value == subAccountId)[0]
      .ownersPermissionsList.some(
        (x) =>
          x.permissionName === "EmbryoTransferRightPurchaceAgreement" || x.permissionName === "All"
      );
    return AgentHasPermission;
  }

  const getUserID = () => {
    const userID =
      userProfile.accountType !== "Agent" ? profile.value : subAccountId;
    return userID;
  };

  const getSelectedAccountName = () => {
    const selectAccount = userProfile.subAccounts.filter(
      (x) => x.value == subAccountId
    );
    return selectAccount[0];
  };

  const dismissAll = () => toast.dismiss();

  const handelCancelRequest = () => {
    let params = {
      serviceId: id,
      ownerId: localStorage.getItem("EAHSUserID"),
    };

    dispatch(FetchCancelRequestthunk(params));
  };

  const handelApproveRejectRequest = (status) => {
    _status.current = status;

    let params = {
      id: id,
      status: status,
      comment: formModel.buyerComment,
    };

    dispatch(FetchApproveRejectRequestCoveringCertificatethunk(params));
  };

  const closeModal = () => setOpenPopup(false);
  const closeModalCancel = () => setOpenPopupCancel(false);

  // asyunc search
  const promiseOptions = (searchKeyword) =>
    new Promise((resolve) => {
      if (timeoutId > 0) {
        clearTimeout(timeoutId);
      }

      const id = setTimeout(async () => {
        const response = await axiosInstance.post(
          "/StudBook/FilterOwnersOrUsers",
          {
            langauge: localStorage.getItem("eahsLang") ?? "en",
            ownerId: searchKeyword,
            skip: 0,
            take: 10000,
            tableType: "user",
          }
        );

        const data = await response.data.ownersList;

        const options = data.map((item) => ({
          label: item.ownerId + " - " + item.name,
          value: item.id,
          details: item,
        }));
        resolve(options);
      }, 1000);

      setTimeoutId(id);
    });

  //Effect
  useEffect(() => {
    if (id != null) {
      let params = {
        language: currentLanguage,
        id: id,
      };

      dispatch(FetchGetServicesRequestByIdthunk(params));
    }

    return () => {
      dispatch(resetGetServicesRequestByIdData());
    };
  }, []);

  useEffect(() => {
    if (!!userProfile && userProfile?.haveProfileSignture != true) {
      toast.error(
        translate(
          "web.completeOwnerProfileData",
          "Please complete the personal data of the profile."
        )
      );

      navigate("/profile");
    }

    if (userProfile.accountType == "Agent") {
      if (userProfile.id == subAccountId) {
        navigate("/404-not-found");
      }

      if (!CheckIfAgentHasPermission()) {
        navigate("/404-not-found");
      }
    }

    let subOwnerProfilesParams = {
      id: getUserID(),
      langauge: currentLanguage,
    };
    dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));

    dispatch(FetchGetServiceDefinitionByIdthunk(serviceId));
  }, [currentLanguage]);

  useEffect(() => {
    if (
      Object.keys(getServicesRequestByIdData).length > 0 && (getServicesRequestByIdData.serviceDefinitionId === serviceId)
    ) {
      setFormModel({
        ...formModel,
        eventName: getServicesRequestByIdData.eventName,
        comment: getServicesRequestByIdData.comment,
        requestsType: getServicesRequestByIdData.requestsType,
        paymentStatus: getServicesRequestByIdData.paymentStatus,
        referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,
        embryoTransferRightPurchaceAgreementId: getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.embryoTransferRightPurchaceAgreementId,
        buyerComment: getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.buyerComment != null ? getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.buyerComment : "",

        coveringCertificate: getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.coveringCertificateId,
        coveringCertificateDrop: {
          label: getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.coveringCertificateName,
          value: getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.coveringCertificateId,
        },

        mareHorse: getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.mareHorse,
        stallionHorse: getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.stallionHorse,
        mareOwner: getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.mareOwner,
        stallionOwner: getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.stallionOwner,
        coveringDate: getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.coveringDate,
        breeder: getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.breeder,

        buyer: getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.buyerOwnerId,
        buyerDrop: {
          label:
            getServicesRequestByIdData.ownerId +
            " - " +
            getServicesRequestByIdData.ownerName,
          value: getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.buyerOwnerId,
        },
        phone: getServicesRequestByIdData.ownerPhone,
        email: getServicesRequestByIdData.ownerEmail,
        idNumber: getServicesRequestByIdData.idNumber,

        agreementDate: getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.agreementDate != null ? dayjs(getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.agreementDate).format("YYYY-MM-DD") : null,
      });

      //another owner
      if (
        getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.ownerId !==
        getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.createdBy &&
        subOwnerProfilesByIdData.length > 0
      ) {
        let params = {
          ownerId: getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.ownerId,
          skip: 0,
          take: 1000,
          langauge: currentLanguage,
          horseTypeFilter: "1",
          searchValue: "",
          sortBy: "-1",
        };
        dispatch(FetchOwnerHorsesDropDownthunk(params));

        //update profile
        setProfile({
          label:
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.ownerId
            ).registerNo +
            " - " +
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.ownerId
            )[`text${currentLanguage === "en" ? "" : "Ar"}`],
          value: subOwnerProfilesByIdData.filter(
            (item) =>
              item.value == getServicesRequestByIdData.embryoTransferRightPurchaceAgreement.ownerId
          ).value,
        });
      }

      //options
      setCheckedItems({
        option1: true
      });
    }
  }, [
    getServicesRequestByIdData,
  ]);

  useEffect(() => {
    //save
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      if (_status.current === "Approve") {
        toast.success(
          translate(
            "web.yourRequestHasSuccessfully",
            "Your Request Has Successfully"
          )
        );
      } else {
        toast.success(
          translate("web.dataSavedSuccessfully", "Data Saved Successfully")
        );
      }

      dispatch(resetToasterMessageServicesRequests());

      setTimeout(() => {
        navigate(`/profile?activeTab=${"e_services"}`);
      }, 1000);
    }

    //error
    else if (toasterMessageServicesRequests != null && toasterMessageServicesRequests !== "save") {
      toast.error(
        toasterMessageServicesRequests
      );

      dispatch(resetToasterMessageServicesRequests());
    }
  }, [toasterMessageServicesRequests]);

  //consoles
  console.log(formModel)

  return (
    <div className="container-fluid container-fluid-padding">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${servicesRequestsLoading
          ? "classdisplay"
          : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>
      {
        //toast
      }
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />
      {
        //content page
      }
      <div className="row rtl">
        <div className="col-md-12 col-sm-12 row rtl">
          {
            //Request Embryo Transfer Right Purchace Agreement title
          }
          <div className="col-md-12 col-sm-12 mb-4">
            <h1 className="fontWeight display-inline">
              {currentLanguage === "en"
                ? getServiceDefinitionByIdData.nameEn
                : getServiceDefinitionByIdData.nameAr}
            </h1>
            <h3 className="fontWeight display-inline">
              {formModel.referenceNumber}{" "}
            </h3>
          </div>
          {
            // Selected Account
          }
          {subOwnerProfilesByIdData.length > 0 &&
            (action == null || action === "edit") &&
            (
              <div className="col-md-6 col-sm-12 mb-5">
                {userProfile?.accountType !== "Agent" ? (
                  <>
                    <label className="capatalize_text">
                      {translate("web.selectedAccount", "Selected Account")}
                    </label>
                    <Select
                      className="mt-10 zindex200"
                      options={[
                        {
                          label:
                            userProfile?.ownerRegisterNo +
                            " - " +
                            userProfile[
                            `fullName${currentLanguage === "en" ? "" : "Ar"}`
                            ],
                          value: localStorage.getItem("EAHSUserID"),
                        },
                        ...(!!subOwnerProfilesByIdData &&
                          !!subOwnerProfilesByIdData.length > 0 &&
                          subOwnerProfilesByIdData.map((a) => ({
                            label:
                              a.registerNo +
                              " - " +
                              a[`text${currentLanguage === "en" ? "" : "Ar"}`],
                            value: a.value,
                          }))),
                      ]}
                      value={profile}
                      onChange={(e) => {
                        handelSelectedAccountChange(e);
                      }}
                      placeholder={localStorage.getItem("EAHSUserFullName")}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      styles={customStyles}
                      key={"EAHSUserFullName"}
                    />
                  </>
                ) : (
                  <label className="capatalize_text">
                    {getSelectedAccountName().registerNo +
                      " - " +
                      getSelectedAccountName()[
                      `text${currentLanguage === "en" ? "" : "Ar"}`
                      ]}
                  </label>
                )}
              </div>
            )}
          {
            //Covering Certificate details title
          }
          <h2 className="fontWeight colorred">
            {translate("web.coveringCertificateDetails", "Covering Certificate Details")}
          </h2>
          {
            //Covering Certificate content
          }
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
            <div className="row mx-0 col-md-12 col-sm-12 rtl">
              <div className="col-md-4 col-sm-12 mt-3">
                <label>
                  {translate("web.coveringCertificate", "Covering Certificate")}
                  <i className="colorred"> *</i>
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.coveringCertificate", "Covering Certificate"),
                      value: "",
                    },
                    ...(!!ownerHorsesDropDownData &&
                      ownerHorsesDropDownData.filter(q => q.gender === "Mare").map((a) => ({
                        label: a.registerNumber + " - " + a.name,
                        value: a.id,
                      }))),
                  ]}
                  value={formModel.coveringCertificateDrop}
                  onChange={(e) => handleFormChange(e, "coveringCertificate")}
                  placeholder={translate("web.coveringCertificate", "Covering Certificate")}
                  name={"coveringCertificate"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  autoFocus={true}
                  key={"horse"}
                  isDisabled={
                    (action === "approve" || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorCoveringCertificate }} className="errorCoveringCertificate">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.mareHorse", "Mare Horse")}
                  name={"mareHorse"}
                  type={"text"}
                  disabled={true}
                  value={formModel.mareHorse}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.stallionHorse", "Stallion Horse")}
                  name={"stallionHorse"}
                  type={"text"}
                  disabled={true}
                  value={
                    formModel.stallionHorse
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.mareOwner", "Mare Owner")}
                  name={"mareOwner"}
                  type={"text"}
                  disabled={true}
                  value={formModel.mareOwner}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.stallionOwner", "stallion Owner")}
                  name={"stallionOwner"}
                  type={"text"}
                  disabled={true}
                  value={formModel.stallionOwner}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.breeder", "Breeder")}
                  name={"breeder"}
                  type={"text"}
                  disabled={true}
                  value={formModel.breeder}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.coveringDate", "CoveringDate")}
                  name={"coveringDate"}
                  type={"text"}
                  disabled={true}
                  value={formModel.coveringDate}
                />
              </div>
            </div>
          </div>
          {
            //Buyer details title
          }
          <h2 className="fontWeight colorred">
            {translate("web.buyerDetails", "Buyer Details")}
          </h2>
          {
            //Buyer content
          }
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
            <div className="col-md-6 col-sm-12">
              <label>
                {translate("web.owner", "Owner")}
                <i className="colorred"> *</i>
              </label>
              <AsyncSelect
                isClearable
                loadOptions={promiseOptions}
                defaultOptions={false} // Set to true to load initial options
                onInputChange={(newValue) => setInputValue(newValue)}
                inputValue={inputValue}
                value={formModel.buyerDrop}
                loadingMessage={() => translate("web.loading", "Loading")}
                onChange={(e) => handleFormChange(e, "buyer")}
                placeholder={
                  translate("web.typeToSearch", "Type To Search") + "..."
                }
                noOptionsMessage={() =>
                  translate("web.noOptions", "No Options")
                }
                isDisabled={action === "view" || action === "approve"}
              />
              <span style={{ color: "red", display: errorBuyer }} className="errorBuyer">
                {translate("web.requiredField", "Required Field")}
              </span>
            </div>
            <div className="col-md-6 col-sm-12">
              <NetaqInput
                label={translate("web.smsPhoneNo", "Mobile No")}
                name={"mobileNo"}
                type={"text"}
                disabled={true}
                value={formModel.phone}
              />
            </div>
            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate(
                  "web.passportOrEmiratesId",
                  "Passport - EmiratesId"
                )}
                name={"name"}
                type={"text"}
                disabled={true}
                value={formModel.idNumber}
                isDash={true}
              />
            </div>
            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.email", "Email")}
                name={"email"}
                type={"text"}
                disabled={true}
                value={formModel.email}
              />
            </div>
          </div>
          {
            //agreement date title
          }
          <h2 className="fontWeight colorred">
            {translate("web.agreementDate", "Agreement Date")}
          </h2>
          {
            //agreement date content
          }
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
            <div className="col-md-6 col-sm-12 rtl service">
              <NetaqInput
                htmlTags={
                  formModel.agreementDate ?
                    <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                      {
                        dayjs(formModel.agreementDate).format("DD/MM/YYYY")
                      }
                    </p> :
                    <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                      DD/MM/YYYY
                    </p>
                }
                name={"agreementDate"}
                type={"normalDateService"}
                label={translate("web.agreementDate", "Agreement Date")}
                onChange={(e) => handleFormChange(e, "agreementDate")}
                value={formModel.agreementDate}
                required={true}
                maxDate={dayjs().format("YYYY-MM-DD")}
                disabled={
                  (action === "approve" || action === "view") ?
                    true
                    : false
                }
              />
              <span style={{ color: "red", display: errorAgreementDate }} className="errorAgreementDate">
                {translate("web.requiredField", "Required Field")}
              </span>
            </div>
          </div>
          {
            //Comments
          }
          {(!!formModel.comment ||
            !!formModel.buyerComment ||
            action === "approve") && (
              <>
                <h2 className="fontWeight colorred">
                  {translate("web.comments", "Comments")}
                </h2>
                <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
                  {
                    // eahs Comment
                  }
                  {!!formModel.comment && (
                    <div className="col-md-6 col-sm-12">
                      <NetaqInput
                        label={translate("web.eahsComments", "EAHS Comments")}
                        name={"adminComment"}
                        type={"textarea"}
                        maxchars={1150}
                        value={formModel.comment}
                        disabled={true}
                      />
                    </div>
                  )}
                  {
                    // buyer Comment
                  }
                  {((action === "approve" && ((formModel.eventName === "SentBack" && !!formModel.buyerComment) || (formModel.eventName !== "SentBack" && (formModel.buyerComment === "" || formModel.buyerComment === null)))) ||
                    (action === "edit" &&
                      formModel.eventName === "BuyerRejected") ||
                    (action === "view" &&
                      !!formModel.buyerComment)) && (
                      <div className="col-md-6 col-sm-12 ">
                        <NetaqInput
                          label={translate("web.buyerComment", "Buyer Owner Comment")}
                          name={"buyerComment"}
                          type={"textarea"}
                          placeholder={translate(
                            "web.buyerComment",
                            "Buyer Owner Comment"
                          )}
                          maxchars={1150}
                          onChange={handleFormChange}
                          value={formModel.buyerComment}
                          disabled={
                            action === "approve" && formModel.eventName !== "SentBack" &&
                              formModel.requestsType === "Portal"
                              ?
                              false
                              : true
                          }
                        />
                      </div>
                    )}
                </div>
              </>
            )}
          {
            //terms
          }
          <h2 className="fontWeight colorred">
            {translate("web.terms", "Terms")}
          </h2>
          <div className="col-md-12 col-sm-12 rtl p0">
            <NetaqInput
              type={"checkbox"}
              label={translate(
                "web.ServiceGeneralNote",
                "Service General Note"
              )}
              name="option1"
              checked={checkedItems.option1}
              onChange={handleCheckboxChange}
              disabled={formModel.eventName === "" ? false : true}
            />
          </div>
          {
            // buttons
          }
          <div className="col-md-12 col-sm-12 mt-15 text-end">
            {
              //cancel
            }
            {
              (action === "edit" && formModel.eventName !== "Draft") &&
              <ActionButton
                className={"btn-danger requestBtn"}
                type={"requests"}
                label={translate("web.cancel", "Cancel")}
                text={
                  <>
                    <i className="fa-solid fa-circle-xmark fa-lg marginrightleft-10"></i>
                    {translate("web.cancel", "Cancel")}
                  </>
                }
                margin={"auto 10px"}
                width={"250px"}
                onClick={() => {
                  setOpenPopupCancel((o) => !o);
                }}
              />
            }
            {
              //draft/submit
            }
            {
              action == null ||
                action === "edit" ||
                (action === "approve" && formModel.eventName === "SentBack") ? (
                <>
                  <ActionButton
                    type={"requests"}
                    className="btn buttonformshowentrycard height46 linkbtn"
                    label={translate("web.draft", "Draft")}
                    text={
                      <>
                        <i className="fa fa-save fa-lg marginrightleft-10"></i>
                        {translate("web.draft", "Draft")}
                      </>
                    }
                    margin={"auto 10px"}
                    width={"250px"}
                    onClick={() => handelFormSubmit("Draft")}
                    disabled={
                      formModel.eventName === "" ||
                        formModel.eventName === "Draft"
                        ? false
                        : true
                    }
                  />
                  <ActionButton
                    type={"requests"}
                    className="btn-success text-white requestBtn"
                    label={translate("web.submit", "Submit")}
                    text={
                      <>
                        <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                        {translate("web.submit", "Submit")}
                      </>
                    }
                    margin={"auto 25px"}
                    width={"200"}
                    onClick={() =>
                      handelFormSubmit("Submit")
                    }
                  />
                </>
              ) : action === "approve" && formModel.eventName !== "SentBack" ? (
                //approve/reject btn
                <>
                  <div className="row">
                    <div className="col-md-2 col-sm-12 d-flex flex-column justify-content-center ms-auto">
                      <ActionButton
                        type={"submit"}
                        label={translate("web.reject", "Reject")}
                        text={
                          <>
                            <i className="fa-solid fa-ban fa-lg marginrightleft-10"></i>
                            {translate("web.reject", "Reject")}
                          </>
                        }
                        additionalText={`
                          ${translate(
                          "web.rejectBuyerComments",
                          "*To Reject The Request Please Enter The Buyer Comment*"
                        )}
                        `}
                        margin={"auto 10px"}
                        width={"100%"}
                        onClick={() => {
                          setOpenPopup((o) => !o);
                        }}
                        disabled={
                          formModel.requestsType === "Portal" &&
                            !!formModel.buyerComment
                            ? false
                            : true
                        }
                      />
                    </div>
                    <div className="col-md-2 col-sm-12" style={{ minWidth: 246, marginLeft: "-46px" }}>
                      <ActionButton
                        className={"btn-success requestBtn"}
                        type={"requests"}
                        label={translate("web.approve", "Approve")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.approve", "Approve")}
                          </>
                        }
                        margin={"auto 10px"}
                        width={"250px"}
                        onClick={() =>
                          handelApproveRejectRequest("Approve")
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )
            }
          </div>
          {
            //pop up
          }
          <Popup
            modal
            lockScroll
            open={openPopup}
            closeOnDocumentClick={false}
            closeOnEscape
            style={{ background: "red" }}
            contentStyle={{ width: "90%", maxWidth: 400 }}
          >
            {(close) => (
              <>
                <div className="">
                  {translate(
                    "web.confirmMsgRejectrequest",
                    "Are you sure to reject request?"
                  )}
                </div>
                <div className="divHorseSelect row rtl ">
                  <div
                    className="bidSubmit buttonformshowentrycard linkbtn col-lg-4 col-sm-6 text-center"
                    onClick={closeModal}
                  >
                    {translate("web.close", "Close")}
                  </div>
                  <div className=" col-lg-6 col-sm-6">
                    <button
                      type="button"
                      className="btn btn-danger btn-lg"
                      onClick={() => {
                        setOpenPopup((o) => !o);
                        handelApproveRejectRequest("Reject");
                      }}
                    >
                      <i className="fa-solid fa-ban fa-lg marginrightleft-10"></i>
                      {translate("web.reject", "Reject")}
                    </button>
                  </div>
                </div>
              </>
            )}
          </Popup>
          {
            //pop up for cancel
          }
          <Popup
            modal
            lockScroll
            open={openPopupCancel}
            closeOnDocumentClick={false}
            closeOnEscape
            style={{ background: "red" }}
            contentStyle={{ width: "90%", maxWidth: 400 }}
          >
            {(close) => (
              <React.Fragment>
                <div className="">
                  {translate(
                    "web.confirmMsgCancelRequest",
                    "Are you sure to cancel request?"
                  )}
                </div>
                <div className="divHorseSelect row rtl">
                  <div
                    className="bidSubmit buttonformshowentrycard linkbtn col-lg-4 col-sm-6 text-center"
                    onClick={closeModalCancel}
                  >
                    {translate("web.close", "Close")}
                  </div>
                  <div className=" col-lg-6 col-sm-6">
                    <button
                      type="button"
                      className="btn btn-danger btn-lg"
                      onClick={() => {
                        setOpenPopupCancel((o) => !o);
                        handelCancelRequest();
                      }}
                    >
                      <i className="fa-solid fa-ban fa-lg marginrightleft-10"></i>
                      {translate("web.cancel", "Cancel")}
                    </button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Popup>
        </div>
      </div>
    </div>
  );
}

import React, { useCallback, useState, useRef, useEffect } from "react";
import NetaqInput from "./NetaqInput";
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import FileUpload from "./FileUpload";
import SimpleUpload from "./SimpleFileUpload";
import axios from "../axios/axios";
import { forEach, transform, values } from "lodash";
import ActionButton from "./ActionButton";
import { useTranslate } from "./TranslationText";
import LoginUaePassOTPForm from "./LoginUaePassOTPForm";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import Loader from "./Loader";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";
import UploadFile from "./UploadFile";
import { Link } from "react-router-dom";
import GrantedPermissionClaimsComponent from "./Profile.GrantedPermissionClaims";
import { convertToCamelCase } from "../helper/convertToCamelCase";
import dayjs from "dayjs";
const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const MIN_AGE = 0;
const MIN_AGENT_AGE = 21;

export default function RegisterForm() {
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const [imageURL, setImageURL] = useState(null);
  const [showOTP, setShowOTP] = useState(false)
  const [otp, setOtp] = useState(null);
  const [registerCred, setRegisterCred] = useState({
    Email: '',
    Password: '',
    ConfirmPassword: '',
    Phone: '',
    IdType: 'EmiratesId',
    IdNumber: '',
    FullName: '',
    Address: 'Detailed Address',
    NationalityId: '',
    NationalityIdDrop: {
      text: translate("web.chooseNationality", "Choose Nationality"),
      value: null,
    },
    selectedemirateId: '',
    selectedemirateIdDrop: {
      label: translate("web.chooseEmirate", "Choose Emirate"),
      value: null,
    },
    CreatedBy: 'UserRegistration',
    FirebaseDeviceId: '12',
    GoogleRecaptchaResponse: ""
  })

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [parentPassPortOrNationaIdFile, setParentPassPortOrNationaIdFile] = useState([]);
  const [passwordsMatch, setPasswordMatch] = useState(true);
  const [userType, setUserType] = useState(1);
  const [accountCategory, setAccountCategory] = useState(1);
  const [uesrGender, setUserGender] = useState(1);
  const [birthDate, setBirthDate] = useState(null);
  const [isYoungerThan21, setIsYoungerThan21] = useState(false);
  const [fatherHasAccount, setFatherHasAccount] = useState(true);
  const [permissionsList, setPermissionsList] = useState([]);
  const [emiratelistoptions, setEmirateListOptions] = useState([]);
  const [regionsList, setRegionsList] = useState([]);

  const [agentPermissionClaims, setAgentPermissionClaims] = useState([]);




  useEffect(() => {
    setFatherHasAccount(true);
  }, [userType])

  async function getEmirateList() {
    try {
      const emiratelist = await axios.get('/lookup/getmaincities')
      const formattedData = await emiratelist.data.result.map((item) => ({
        text: item.name,
        value: item.id,
      }));
      setEmirateListOptions(formattedData)
    } catch (error) {
      window.alert('error in emirates list')
    }

  }
  async function getRegionListbyID(id) {
    try {
      const regionList = await axios.get(`/lookup/getareasbyemirateid?EmirateId=${id}`)
      const formattedData = await regionList.data.result.map((item) => ({
        text: item.name,
        value: item.id,
      }));
      setRegionsList(formattedData)
    } catch (error) {
      window.alert('error in region list')
    }

  }
  async function getPermissionList() {
    const lang = localStorage.getItem("eahsLang") ?? 'en';
    try {
      const regionList = await axios.get(`/lookup/getpermissions`)
      const formattedData = await regionList.data.result.map((item) => ({
        text: lang == 'en' ? item.permissionNameEn : item.permissionNameAr,
        value: item.id,
        label: lang == 'en' ? item.permissionNameEn : item.permissionNameAr
      }));
      setPermissionsList(formattedData)
    } catch (error) {
      window.alert('error in region list')
    }

  }





  const handleFatherFileChange = (e) => {
    let checkFileSize = true;
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        checkFileSize = false;
      } else {
        if (e.target.files && e.target.files.length > 0) {
          // Check allowed file types
          const file = e.target.files[0];
          const allowedMimeTypes = ['image'];
          if (!allowedMimeTypes.some(type => file.type.startsWith(type))) {
            toast.error(translate("web.invalidFileType", "Only images and PDFs allowed."));
            return; // Skip to the next file if type is not allowed
          }

          var reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function () {
            setFatherImageURL(reader.result);
          };
        }
      }
    }

    if (!checkFileSize) {
      toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
    }
  };
  const handleSignitureFileChange = (e) => {
    let checkFileSize = true;
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        checkFileSize = false;
      } else {
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0];
          const allowedMimeTypes = ['image'];
          if (!allowedMimeTypes.some(type => file.type.startsWith(type))) {
            toast.error(translate("web.invalidFileType", "Only images and PDFs allowed."));
            return; // Skip to the next file if type is not allowed
          }
          var reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function () {
            setImageURL(reader.result);
          };
        }
      }
    }

    if (!checkFileSize) {
      toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
    }
  };

  const handleChange = (event, input, index = 0) => {
    if (!!input && input === "NationalityId") {
      setRegisterCred((prevRegisterCred) => ({
        ...prevRegisterCred,
        NationalityId: event.value,
        NationalityIdDrop: event,
      }));
      return;
    }
    if (!!input && input.includes("ownerRegNumber")) {
      setRegisterCred((prevRegisterCred) => ({
        ...prevRegisterCred,
        [input]: event.value,
        [`${input}Drop`]: event,
      }));
      return;
    }

    if (!!input && input === "selectedemiret") {
      getRegionListbyID(event.value);
      setRegisterCred((prevRegisterCred) => ({
        ...prevRegisterCred,
        selectedemirateId: event.value,
        selectedemirateIdDrop: event,
        selectedregionDrop: null
      }));
      return;
    }
    if (!!input && input === "region") {
      setRegisterCred((prevRegisterCred) => ({
        ...prevRegisterCred,
        selectedregion: event.value,
        selectedregionDrop: event,
      }));
      return;
    }
    if (!!input && input.includes("premisions")) {
      setRegisterCred((prevRegisterCred) => ({
        ...prevRegisterCred,
        [input]: event.value,
        [`${input}Drop`]: event,
      }));
      return;
    }
    if (event.target.name === 'user-type') {
      setUserType(event.target.value);
    }
    if (event.target.name === 'user-category') {
      setAccountCategory(event.target.value)
      if (event.target.value == 2) {
        setUserGender(3);
        setRegisterCred((prevRegisterCred) => ({
          ...prevRegisterCred,
          [event.target.name]: event.target.value,
          'gender': 3,
        }));
        return;
      } else {
        setUserGender(1);
        return;
      }
    }
    setRegisterCred((prevRegisterCred) => ({
      ...prevRegisterCred,
      [event.target.name]: event.target.value,
    }));

    if (event.target.name === 'Password' || event.target.name === 'ConfirmPassword') {
      if (event.target.name === 'ConfirmPassword' && event.target.value !== registerCred.Password) {
        setPasswordMatch(false);
      } else {
        setPasswordMatch(true);
      }
    }
  };

  const handleFatherRadioChange = (event) => {
    if (event.target.value == 'true') {
      setFatherHasAccount(true);
    } else {
      setFatherHasAccount(false);

    }
  }

  const handleFilesUploaded = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let checkFileSize = true;
      const fileListHook = [];

      for (let fileIndex = 0; fileIndex < e.target.files.length; fileIndex++) {
        if (e.target.files[fileIndex].size > 10 * 1024 * 1024) {
          checkFileSize = false;
          toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
        } else {
          // Check allowed file types
          const file = e.target.files[fileIndex];
          const allowedMimeTypes = ['image/', 'application/pdf'];
          if (!allowedMimeTypes.some(type => file.type.startsWith(type))) {
            toast.error(translate("web.invalidFileType", "Only images and PDFs allowed."));
            continue; // Skip to the next file if type is not allowed
          }
          fileListHook.push({
            documentFile: URL.createObjectURL(e.target.files[fileIndex]),
            name: e.target.files[fileIndex].name,
            file: e.target.files[fileIndex],
          });
        }
      }

      setUploadedFiles(fileListHook);
    }
  };
  const parentNationaIdFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let checkFileSize = true;
      const fileListHook = [];

      for (let fileIndex = 0; fileIndex < e.target.files.length; fileIndex++) {
        if (e.target.files[fileIndex].size > 10 * 1024 * 1024) {
          checkFileSize = false;
          toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
        } else {
          // Check allowed file types
          const file = e.target.files[fileIndex];
          const allowedMimeTypes = ['image', 'application/pdf'];
          if (!allowedMimeTypes.some(type => file.type.startsWith(type))) {
            toast.error(translate("web.invalidFileType", "Only images and PDFs allowed."));
            continue; // Skip to the next file if type is not allowed
          }
          fileListHook.push({
            documentFile: URL.createObjectURL(e.target.files[fileIndex]),
            name: e.target.files[fileIndex].name,
            file: e.target.files[fileIndex],
          });
        }
      }

      setParentPassPortOrNationaIdFile(fileListHook);
    }
  };
  const [hovered, setHovered] = useState({
    sign: false,
    close: false,
    clear: false,
    save: false,
    secondsignature: false,
  });

  const [nationalityOptions, setNationalityOptions] = useState(
    [registerCred.NationalityIdDrop]
  )
  const handleMouseOver = (type) => {
    setHovered((prev) => ({ ...prev, [type]: true }));
  };

  const handleMouseOut = (type) => {
    setHovered((prev) => ({ ...prev, [type]: false }));
  };

  const signatureCanvas = useRef(null);
  const fatherSigniture = useRef(null);

  const [fatherImageUrl, setFatherImageURL] = useState(null)

  const onFatherClearSignature = () => fatherSigniture.current.clear();
  const onFatherSaveSignature = (onCloseSignaturePad) => {
    if (!fatherSigniture.current?.isEmpty()) {
      const imageData = fatherSigniture.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setFatherImageURL(imageData);
      onCloseSignaturePad();
    }
  };
  const onClearSignature = () => signatureCanvas.current.clear();
  const onSaveSignature = (onCloseSignaturePad) => {
    if (!signatureCanvas.current?.isEmpty()) {
      const imageData = signatureCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setImageURL(imageData);
      onCloseSignaturePad();
    }
  };
  const generateButtonStyle = (isHovered) => ({
    background: isHovered ? "#ffffff" : "#E44D52",
    padding: "10px 20px",
    color: isHovered ? "#E44D52" : "#fff",
    outline: 0,
    border: "solid",
    borderWidth: 1,
    borderColor: "#E44D52",
    transition: "all 0.3s",
    display: "block",
    margin: "auto",
    cursor: "pointer",
  });

  const buttonSubmitStyle = generateButtonStyle(hovered.sign);
  const buttonsignatureStyle = generateButtonStyle(hovered.secondsignature);
  const buttoncloseStyle = generateButtonStyle(hovered.close);
  const buttonclearStyle = generateButtonStyle(hovered.clear);
  const buttonSaveStyle = generateButtonStyle(hovered.save);

  async function getNationalityList() {
    try {
      const nationalityList = await axios.get('/nationality')
      const formattedData = await nationalityList.data.result.map((item) => ({
        text: item.name,
        value: item.id,
      }));
      setNationalityOptions(formattedData)
    } catch (error) {

    }

  }

  useEffect(() => {
    getNationalityList();
    getPermissionList();
    getEmirateList();
  }, [])
  let agentPermissionList = [];
  const [issubmitting, setIsSubmitting] = useState(false);
  // subnit Rigester Form
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (uploadedFiles.length <= 0) {
      toast.error(translate('web.idCopyIsRequired', 'Id Copy Is Required'));
      return;
    }
    if (userType == 2) {
      if (imageURL == null) {
        toast.error(translate('web.signitureIsRequired', 'Signiture is Required'));
        return;
      }
      if (isYoungerThan21 == true) {
        if (fatherHasAccount == false) {
          if (fatherImageUrl == null) {
            toast.error(translate('web.fatherSignitureIsRequired', 'Father Signiture is Required'));
            return;
          }
          if (parentPassPortOrNationaIdFile.length <= 0) {
            toast.error(translate('web.fatherIdCopyIsRequired', 'Father Id Copy is Required'));
            return;
          }
        }
      }
    }
    if (userType == 4) {
      if (imageURL == null) {
        toast.error(translate('web.signitureIsRequired', 'Signiture is Required'));
        return;
      }
      if (agentPermissionClaims.length > 0) {

        const validationResult = agentPermissionClaims?.reduce((prev, curr) => {
          return {
            isValid: prev.isValid && curr.isValid,
            errors: curr.isValid ? prev.errors : [...prev.errors, { owner: curr.selectedOwner?.label, errors: curr.errors }]
          }
        }, { isValid: true, errors: [] });
        if (!validationResult.isValid) {
          toast.error(translate('web.checkAllAgentRequestedPermisions', 'Check All Agent Requested Permisions'));
          return;
        }
      } else {
        toast.error(translate('web.pleaseEnterOwnerDetails', 'Please Enter Owner Details'));
        return;
      }
    }

    try {
      const validationResult = agentPermissionClaims?.reduce((prev, curr) => {
        return {
          isValid: prev.isValid && curr.isValid,
          errors: curr.isValid ? prev.errors : [...prev.errors, { owner: curr.selectedOwner.label, errors: curr.errors }]
        }
      }, { isValid: true, errors: [] });

      if (!validationResult.isValid) {
        for (let index = 0; index < validationResult.errors.length; index++) {
          const error = validationResult.errors[index];
          toast.error(error.owner + " " + error.errors.join(" , "));
        }

        // TODO: Toast Error
        setIsSubmitting(false)
        return;
      }
    } catch (error) {
      console.log('error ')
      setIsSubmitting(false)
    }


    await recaptchaRef.current.execute();
    setIsSubmitting(true);

    const ownersArray = Object.keys(registerCred)
      .filter(key => key.startsWith('ownerRegNumber') && !key.includes('Drop'))
      .map(key => registerCred[key]); // Mapping to get an array of values

    // Extract authorization documents start dates as an array
    const authFilesStartDates = Object.keys(registerCred)
      .filter(key => key.startsWith('autharizationDocumentsStartDate') && !key.includes('Drop'))
      .map(key => registerCred[key]); // Mapping to get an array of values

    // Extract authorization documents expiry dates as an array
    const authFilesEndDates = Object.keys(registerCred)
      .filter(key => key.startsWith('autharizationDocumentsExpiryDate') && !key.includes('Drop'))
      .map(key => registerCred[key]); // Mapping to get an array of values




    for (let index = 0; index < agentPermissionClaims.length; index++) {

      let agentPermisionObject = {};
      agentPermisionObject.Id = agentPermissionClaims[index]?.selectedOwner?.value ?? '';
      agentPermisionObject.StartDate = new Date(agentPermissionClaims[index]?.fromDate)?.toISOString() ?? '';
      agentPermisionObject.ExpirationDate = new Date(agentPermissionClaims[index]?.toDate)?.toISOString() ?? '';

      const permissionsCustomList = agentPermissionClaims[index]?.selectedPermissions ?? [];
      const sentPermissions = permissionsCustomList.map((permision) => (permision.value));
      agentPermisionObject.PesmissionId = sentPermissions;
      agentPermisionObject.agentAuthFiles = agentPermissionClaims[index]?.agentAuthFiles ?? [];
      // Update the state with the new agent permission object
      agentPermissionList = [...agentPermissionList, agentPermisionObject];


    }

    // Loop through the arrays to create agentPermission objects

    const formData = new FormData();
    for (const [key, value] of Object.entries(registerCred)) {
      if (value instanceof Object && 'value' in value) {
        formData.append(key, value.value);
      } else {
        formData.append(key, value);
      }
    }

    formData.append('GoogleRecaptchaResponse', recaptchaToken);
    formData.append('File', uploadedFiles[0].file);
    formData.append('Signture', imageURL);
    formData.append('AccountCategory', accountCategory);
    if (!!birthDate) {
      formData.append('BirthDate', new Date(birthDate).toISOString());
    }
    formData.append('AccountType', userType);
    if (parentPassPortOrNationaIdFile.length > 0 && isYoungerThan21 && userType == 2) {
      formData.append('ParentPassPortOrNationaIdFile', parentPassPortOrNationaIdFile[0]?.file ?? null);
    }
    formData.append('ParentSignture', fatherImageUrl);
    formData.append('EmirateId', registerCred.selectedemirateId);
    formData.append('AreaId', registerCred.selectedregion ?? "");
    formData.append('Gender', uesrGender ?? 3);
    // formData.append('AgentPermissionsList', agentPermissionList);
    try {
      for (let q = 0; q < agentPermissionList.length; q++) {
        const element = agentPermissionList[q];
        if (!!element.ExpirationDate && !!element.Id && element.PesmissionId.length > 0 && element.agentAuthFiles.length > 0 && !!element.StartDate) {

          formData.append(`AgentPermissionsList[${q}][Id]`, element.Id);

          formData.append(`AgentPermissionsList[${q}][StartDate]`, new Date(element?.StartDate).toISOString());
          formData.append(`AgentPermissionsList[${q}][ExpirationDate]`, new Date(element?.ExpirationDate ?? '').toISOString());
          for (let s = 0; s < element.PesmissionId.length; s++) {
            const perm = element.PesmissionId[s];
            formData.append(`AgentPermissionsList[${q}][PesmissionId][${s}]`, perm);

          }
        }
      }

      const extractedData = agentPermissionClaims.flatMap(fileObj => {
        if (!!fileObj.agentAuthFiles) {
          return fileObj.agentAuthFiles.map((item, index) => ({
            [`Id`]: fileObj.selectedOwner.value,
            [`AuthorizeFile`]: item.file
          }));
        } else return []
      });


      for (let z = 0; z < extractedData.length; z++) {
        formData.append(`AuthFilesList[${z}].Id`, extractedData[z].Id);
        formData.append(`AuthFilesList[${z}].AuthorizeFile`, extractedData[z].AuthorizeFile);
      }

      const register = await axios.post('/account/register', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        }
      })

      if (register.data?.status?.responseCode == 200) {
        toast.success(translate('web.anEmailHasBeenSentToYourEmailWithOTP', 'An Email has been Sent to Your Email with OTP'));
        setShowOTP(true)
        setIsSubmitting(false);

      }
      else if (register.data.responseCode == 100) {
        toast.error(translate('web.invalidRECAPTCHAPleaseTryAgain.', "Invalid reCAPTCHA. Please try again."));
        setIsSubmitting(false);

      }
      else {
        toast.error(translate(`web.${convertToCamelCase(register.data.status.detailedResponseCode)}`, register.data.status.detailedResponseCode));
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error(translate('web.registerFailedPleaseContactAdmin', 'Register Failed Please Contact Admin'));
      setIsSubmitting(false);
      console.log('error is ', error)

    }
  }

  const dismissAll = () => toast.dismiss();

  const handleOTPSubmit = async (event) => {
    event.preventDefault();
    const verfy = await axios.post('/account/complete-register', { email: registerCred.Email, otp: otp },)
    if (verfy.data.status.responseCode == 200) {
      toast.success(translate('web.registerMessage', 'You Account is Pending Verification'));
      setShowOTP(false)
      setTimeout(() => {
        navigate('/');
      }, 5000);
    } else {
      toast.error(translate(`web.${convertToCamelCase(verfy.data.status.detailedResponseCode)}`, verfy.data.status.detailedResponseCode));
    }
  }
  const [recaptchaValidate, setRecaptchaVaidate] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const recaptchaRef = useRef();

  const showToastMsg = () => {
    toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
  };

  const todaysDate = new Date();
  const minAllowedDateOfBirth = `${todaysDate.getFullYear() - MIN_AGE}-${String(todaysDate.getMonth() + 1).padStart(2, '0')}-${String(todaysDate.getDate()).padStart(2, '0')}`;
  const minAllowedAgentDateOfBirth = `${todaysDate.getFullYear() - MIN_AGENT_AGE}-${String(todaysDate.getMonth() + 1).padStart(2, '0')}-${String(todaysDate.getDate()).padStart(2, '0')}`;


  return (
    <>
      <ToastContainer position="top-right" onClick={dismissAll} theme="colored" autoClose={10000} />
      <form className="col-lg-12 col-md-12 col-sm-12 row m0 rtl" onSubmit={handleSubmit} >
        {/* <h2 className="color-eahsred">{translate("web.userInformation", "User Information")}</h2> <br /> */}
        <div
          className="row RegisterFormRow rtl" style={{ display: 'flex', flexDirection: 'column' }}
        >
          {/* <div style={{position:'absolute' , display:'flex' , top:63}}>
            <ActionButton  isPressed={accountCategory == 1} onClick={()=> setAccountCategory(1)} type={'normalbtn'} margin={'auto 15px'}  text={translate('web.individual', 'Individual')} />
            <ActionButton  isPressed={accountCategory == 2} onClick={()=> setAccountCategory(2)} type={'normalbtn'} margin={'auto 0px'}  text={
            } />
          </div> */}
          <h3 className="col-lg-12 col-md-12 col-sm-12">
            {translate('web.accountCategory', 'Account Category')}
          </h3>
          <div className="row p0 rtl radiofelxwrap" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', padding: '0 50px' }}>
            <div className="col-lg-3 col-md-3 col-sm-6 tooltip p0 m0 rtl">
              <span class="tooltiptext">{translate('web.individualToolTip', 'To Enroll Biddings Or Book A Table')} </span>
              <NetaqInput
                type={"radio"}
                name={"user-category"}
                label={translate('web.individual', 'Individual')}
                value={1}
                checked={true}
                info={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 tooltip p0 m0 rtl">
              <span class="tooltiptext">{translate('web.organizationToolTip', 'If You Have Are In UAE And You Have Hourses Not Registerd In EAHS')}</span>

              <NetaqInput
                type={"radio"}
                name={"user-category"}
                label={translate('web.organization', 'Organization')}
                value={2}
                info={true}
                onChange={handleChange}
              />
            </div>

          </div>
          <h3 className="col-lg-12 col-md-12 col-sm-12">
            {translate('web.accountType', 'Account Type')}
          </h3>
          <div className="row p0 rtl radiofelxwrap" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', padding: '0 50px' }}>
            <div className="col-lg-3 col-md-3 col-sm-6 tooltip p0 m0 rtl">
              <span class="tooltiptext">{translate('web.basicAccountToolTip', 'To Enroll Biddings Or Book A Table')} </span>
              <NetaqInput
                type={"radio"}
                name={"user-type"}
                label={translate('web.basicAccount', 'Basic Account')}
                value={1}
                checked={true}
                info={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 tooltip p0 m0 rtl">
              <span class="tooltiptext">{translate('web.localOwnerToolTip', 'If You Have Are In UAE And You Have Hourses Not Registerd In EAHS')}</span>

              <NetaqInput
                type={"radio"}
                name={"user-type"}
                label={translate('web.localOwner', 'Local Owner')}
                value={2}
                info={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 tooltip p0 m0 rtl">
              <span class="tooltiptext">{translate('web.internationalOwnerToolTip', 'If You have Hourses OutSide UAE And NOT without Regstration Number')}</span>

              <NetaqInput
                type={"radio"}
                name={"user-type"}
                label={translate('web.internationalOwner', 'International Owner')}
                value={3}
                info={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 tooltip  p0 m0 rtl">
              <span class="tooltiptext">{translate('web.agentToolTip', 'This Account Type To Manage Owenrs Hourses In Our System')}</span>

              <NetaqInput
                type={"radio"}
                name={"user-type"}
                label={translate('web.agent', 'Agent')}
                value={4}
                info={true}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row rtl" >
            <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl ">
              <NetaqInput
                type={"text"}
                label={translate("web.fullName", "Full Name")}
                placeholder={translate("web.fullName", "Full Name")}
                name={"FullName"}
                onChange={handleChange}
                required={true}
              />
            </div>
            <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl ">
              <NetaqInput
                type={"text"}
                label={translate("web.fullNameArabic", "Full Name Arabic")}
                placeholder={translate("web.fullNameArabic", "Full Name Arabic")}
                name={"FullNameAr"}
                onChange={handleChange}
                required={userType == 2 || userType == 4 ? true : false}
              />
            </div>
            <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl ">
              <NetaqInput
                type={"tel"}
                label={translate("web.mobileNo", "Mobile No")}
                placeholder={translate("web.mobileNo", "Mobile No")}
                name={"Phone"}
                onChange={handleChange}
                required={true}
              />
            </div>
            <div className="col-lg-6 mt-2 col-md-6 col-sm-12   rtl">
              <NetaqInput
                type={"email"}
                label={translate("web.email", "Email")}
                placeholder={translate("web.email", "Email")}
                name={"Email"}
                onChange={handleChange}
                required={true}

              />
            </div>
            <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl ">
              <NetaqInput
                name={"Password"}
                key={"password"}
                placeholder={translate("web.password", "Password")}
                label={translate("web.password", "Password")}
                type={"password"}
                onChange={handleChange}
                required={true}
              />
            </div>
            <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl ">
              <NetaqInput
                name={"ConfirmPassword"}
                key={"password-Confirm"}
                placeholder={translate("web.confirmPassword", "Confirm Password")}
                type={"password"}
                label={translate("web.confirmPassword", "Confirm Password")}
                onChange={handleChange}
                required={true}
              />
            </div>
            {!passwordsMatch && <div className="col-lg-12 col-md-12 col-sm-12  rtl  text-danger">
              {translate("web.passwordsAreNotMatched", "Passwords Are Not Matched!!")}
            </div>}

            {uesrGender !== 3 &&
              <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl">
                <NetaqInput
                  type={"normalDate"}
                  label={translate('web.birthDate', "Birth Date")}
                  name={"birthdate"}
                  required={true}
                  value={birthDate}
                  maxDate={userType == 4 ? minAllowedAgentDateOfBirth : minAllowedDateOfBirth}
                  onChange={(e) => {
                    let now = new Date();
                    now.setHours(0, 0, 0, 0);
                    now.setFullYear(now.getFullYear() - 21);
                    setIsYoungerThan21(new Date(e.target.value) > now ? true : false);
                    setBirthDate(e.target.value)
                  }}
                />
              </div>
            }
            {uesrGender !== 3 &&
              <>
                <div className={userType != 1 && userType != 4 ? "col-lg-6  col-md-6 col-sm-12 align-content-center row m0 p0  mt-2 rtl form-group" : "form-group col-lg-6 m0 mt-2 p0 col-md-6 col-sm-12  row align-content-center  rtl"} >

                  <label className="capatalize_text">
                    {translate('web.gender', 'Gender')} :
                  </label>
                  <div className="row mx-0 rtl">
                    <div className="col-lg-6  col-md-6 col-sm-6 align-content-center  rtl bold">
                      <NetaqInput
                        type={"radio"}
                        label={translate('web.male', 'Male')}
                        name={"gender"}
                        value={1}
                        checked={true}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6  col-md-6 col-sm-6 align-content-center  rtl bold" >
                      <NetaqInput
                        type={"radio"}
                        label={translate('web.female', 'Female')}
                        name={"gender"}
                        value={2}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </>
            }
            <div className="col-lg-6  mt-2 col-md-6 col-sm-12  rtl">
              <NetaqInput
                type={"select"}
                label={translate("web.choosenationality", "Choose Nationality")}
                placeholder={translate("web.choosenationality", "Choose Nationality")}
                name={"NationalityId"}
                listSelect={nationalityOptions}
                value={registerCred.countryDrop}
                onChange={(evt) => handleChange(evt, "NationalityId")}
                required={true}

              />
            </div>

            <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl ">
              <NetaqInput
                type={"text"}
                label={translate("web.address", "Address")}
                placeholder={translate("web.address", "Address")}
                name={"Address"}
                onChange={handleChange}
                required={true}
              />
            </div>

            {userType != 3 && accountCategory == 1 &&
              <>
                <div className="col-lg-6  col-md-6 col-sm-12  rtl">
                  <div className="row rtl mt-2 form-group" >

                    <label className="capatalize_text">
                      {translate('web.idType', 'ID Type')} :
                    </label>
                    <div className="col-lg-6 col-md-6 col-sm-6  align-content-center  rtl ">
                      <NetaqInput
                        type={"radio"}
                        name={"IdType"}
                        label={translate("web.emiratesId", "Emirates Id")}
                        value={"EmiratesId"}
                        checked={true}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6  align-content-center  rtl">
                      <NetaqInput
                        type={"radio"}
                        name={"IdType"}
                        label={translate("web.passport", "Passport")}
                        value={"Passport"}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 rtl mt-2 ">
                  <NetaqInput
                    key={'IDTYPE'}
                    type={"text"}
                    label={registerCred.IdType == 'EmiratesId' ? translate("web.idNumber", "ID Number") : translate("web.passportNumber", "Passport Number")}
                    placeholder={'123456789123456'}
                    name={"IdNumber"}
                    onChange={handleChange}
                    required={true}
                  />
                </div>
              </>
            }
            {userType == 3 && accountCategory == 1 &&
              <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl ">
                <NetaqInput
                  type={"text"}
                  label={translate("web.passportNr", "Passport Nr")}
                  placeholder={translate("web.passportNr", "Passport Nr")}
                  name={"IdNumber"}
                  onChange={handleChange}
                  required={true}
                />
              </div>
            }
            {accountCategory == 2 &&
              <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl">
                <NetaqInput
                  type={"text"}
                  label={translate("web.refCRNr", "Ref. CR Nr")}
                  placeholder={translate("web.refCRNr", "Ref. CR Nr")}
                  name={"crnumber"}
                  onChange={handleChange}
                  required={true}
                />
              </div>
            }
            {(userType == 2 || userType == 4) &&
              <>
                <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl">
                  <NetaqInput
                    type={"select"}
                    label={translate("web.chooseEmirate", "Choose Emirate")}
                    placeholder={translate("web.chooseEmirate", "Choose Emirate")}
                    name={"selectedemiret"}
                    listSelect={emiratelistoptions}
                    value={registerCred.selectedemirateIdDrop}
                    onChange={(evt) => handleChange(evt, "selectedemiret")}
                    required={true}
                  />
                </div>
                <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl">
                  <NetaqInput
                    type={"select"}
                    label={translate("web.chooseRegion", "Choose Region")}
                    placeholder={translate("web.chooseRegion", "Choose Region")}
                    name={"region"}
                    listSelect={regionsList}
                    value={registerCred.selectedregionDrop}
                    onChange={(evt) => handleChange(evt, "region")}
                    required={true}
                  />
                </div>
              </>
            }
            {accountCategory == 1 &&
              <>
                <div className={'col-lg-6 mt-2 col-md-6 col-sm-12  rtl form-group'} >
                  <label className="capatalize_text">
                    {registerCred.IdType == 'EmiratesId' ? translate('web.idCopy', 'ID Copy') : translate('web.passportCopy', 'Passport Copy')} <span className="color-eahsred" >*</span>
                  </label>
                  <SimpleUpload accept="image/*,application/pdf" isMulti={false} isServer={false} initialFiles={uploadedFiles} onChange={handleFilesUploaded} key={'ID UPLOAD'} />
                </div>
              </>
            }
            {accountCategory == 2 &&
              <>
                <div className={"col-lg-6 mt-2 col-md-6 col-sm-12  rtl form-group"}>
                  <label className="capatalize_text">
                    {translate('web.attachmentCRCopy', 'Attachment CR Copy')} <span className="color-eahsred" > *</span>
                  </label>
                  <SimpleUpload accept="image/*,application/pdf" isMulti={false} isServer={false} initialFiles={uploadedFiles} onChange={handleFilesUploaded} key={'CR UPLOAD'} />
                </div>
              </>
            }
            {userType != 1 && accountCategory == 1 &&
              <>
                {userType == 3 && accountCategory == 1 &&
                  <>
                    <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl form-group row rtl">
                      <label className="capatalize_text">
                        {translate('web.ownersSignature', 'Owner’s signature')} ({translate('web.optional', 'Optional')})
                      </label>
                      <div className="col-lg-12 col-md-12 col-sm-12 rtl">
                        <div className="row mx-0 rtl">
                          <Popup
                            modal
                            lockScroll
                            trigger={
                              <div className="col-5 text-center" >
                                <span
                                  style={buttonSubmitStyle}
                                  onMouseOver={() => handleMouseOver("sign")}
                                  onMouseOut={() => handleMouseOut("sign")}
                                >
                                  {translate('web.openSigniturePad', 'Open Signiture Pad')}
                                </span>
                              </div>
                            }
                            closeOnDocumentClick={false}
                            closeOnEscape
                          >
                            {(close) => (
                              <>
                                <SignaturePad
                                  ref={signatureCanvas}
                                  canvasProps={{
                                    className: "signatureCanvas",
                                  }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <button
                                    style={buttonSaveStyle}
                                    onMouseOver={() => handleMouseOver("save")}
                                    onMouseOut={() => handleMouseOut("save")}
                                    onClick={() => {
                                      onSaveSignature(close);
                                    }}
                                  >
                                    {translate('web.save', 'save')}

                                  </button>
                                  <button
                                    style={buttonclearStyle}
                                    onMouseOver={() => handleMouseOver("clear")}
                                    onMouseOut={() => handleMouseOut("clear")}
                                    onClick={onClearSignature}
                                  >
                                    {translate('web.clear', 'Clear')}

                                  </button>
                                  <button
                                    style={buttoncloseStyle}
                                    onMouseOver={() => handleMouseOver("close")}
                                    onMouseOut={() => handleMouseOut("close")}
                                    onClick={close}
                                  >
                                    {translate('web.close', 'Close')}
                                  </button>
                                </div>
                              </>
                            )}
                          </Popup>
                          <span style={{ margin: 'auto 5px' }} className="col-1 uaepassor">
                            {translate("web.or", "OR")}
                          </span>
                          <UploadFile
                            text="Upload Singniture Photo"
                            className="col-5 text-center my-auto"
                            onChange={handleSignitureFileChange}
                            accept="image/*,application/pdf"
                          />
                          {imageURL ? (
                            <img
                              className="ownerSigintureImage"
                              src={imageURL}
                              alt="Owner Signature"
                              style={{
                                width: "100%",
                                maxWidth: 200,
                                maxHeight: 50,
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                }

                {(userType == 4 && accountCategory == 1) &&
                  <>
                    <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl form-group row rtl">
                      <label className="capatalize_text">
                        {translate('web.agentSigniture', 'Agent’s signature')}<span className="color-eahsred" > *</span>
                      </label>
                      <div className="col-lg-12 col-md-12 col-sm-12 rtl">
                        <div className="row mx-0 rtl" >
                          <Popup
                            modal
                            lockScroll
                            trigger={
                              <div className="col-5 text-center">
                                <span
                                  style={buttonSubmitStyle}
                                  onMouseOver={() => handleMouseOver("sign")}
                                  onMouseOut={() => handleMouseOut("sign")}
                                >
                                  {translate('web.openSigniturePad', 'Open Signiture Pad')}
                                </span>
                              </div>
                            }
                            closeOnDocumentClick={false}
                            closeOnEscape
                          >
                            {(close) => (
                              <>
                                <SignaturePad
                                  ref={signatureCanvas}
                                  canvasProps={{
                                    className: "signatureCanvas",
                                  }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <button
                                    style={buttonSaveStyle}
                                    onMouseOver={() => handleMouseOver("save")}
                                    onMouseOut={() => handleMouseOut("save")}
                                    onClick={() => {
                                      onSaveSignature(close);
                                    }}
                                  >
                                    {translate('web.save', 'Save')}
                                  </button>
                                  <button
                                    style={buttonclearStyle}
                                    onMouseOver={() => handleMouseOver("clear")}
                                    onMouseOut={() => handleMouseOut("clear")}
                                    onClick={onClearSignature}
                                  >
                                    {translate('web.clear', 'Clear')}
                                  </button>
                                  <button
                                    style={buttoncloseStyle}
                                    onMouseOver={() => handleMouseOver("close")}
                                    onMouseOut={() => handleMouseOut("close")}
                                    onClick={close}
                                  >
                                    {translate('web.close', 'Close')}
                                  </button>
                                </div>
                              </>
                            )}
                          </Popup>
                          <span style={{ margin: 'auto 5px' }} className="col-1 uaepassor">
                            {translate("web.or", "OR")}
                          </span>
                          <UploadFile
                            text="Upload Singniture Photo"
                            className="col-5 text-center my-auto"
                            onChange={handleSignitureFileChange}
                            accept="image/*,application/pdf"
                          />
                          {imageURL ? (
                            <img
                              className="ownerSigintureImage"
                              src={imageURL}
                              alt="Owner Signature"
                              style={{
                                width: "100%",
                                maxWidth: 200,
                                maxHeight: 50,
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                }

              </>
            }
            {(userType == 2 && accountCategory == 1) &&
              <>
                <div
                  className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl form-group row rtl "
                >
                  <label className="capatalize_text">
                    {translate('web.ownersSignature', 'Owner’s signature')}<span className="color-eahsred" > *</span>
                  </label>
                  <div className="col-lg-12 col-md-12 col-sm-12   rtl" >
                    <div className="row mx-0 rtl">
                      <Popup
                        modal
                        lockScroll={true}
                        trigger={
                          <div className="col-5 text-center">
                            <span
                              style={buttonSubmitStyle}
                              onMouseOver={() => handleMouseOver("sign")}
                              onMouseOut={() => handleMouseOut("sign")}
                            >
                              {translate('web.openSigniturePad', 'Open Signiture Pad')}
                            </span>
                          </div>
                        }
                        closeOnDocumentClick={false}
                        closeOnEscape
                      >
                        {(close) => (
                          <>
                            <SignaturePad
                              ref={signatureCanvas}
                              canvasProps={{
                                className: "signatureCanvas",
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <button
                                style={buttonSaveStyle}
                                onMouseOver={() => handleMouseOver("save")}
                                onMouseOut={() => handleMouseOut("save")}
                                onClick={() => {
                                  onSaveSignature(close);
                                }}
                              >
                                {translate('web.save', 'Save')}
                              </button>
                              <button
                                style={buttonclearStyle}
                                onMouseOver={() => handleMouseOver("clear")}
                                onMouseOut={() => handleMouseOut("clear")}
                                onClick={onClearSignature}
                              >
                                {translate('web.clear', 'Clear')}
                              </button>
                              <button
                                style={buttoncloseStyle}
                                onMouseOver={() => handleMouseOver("close")}
                                onMouseOut={() => handleMouseOut("close")}
                                onClick={close}
                              >
                                {translate('web.close', 'Close')}
                              </button>
                            </div>
                          </>
                        )}
                      </Popup>
                      <span style={{ margin: 'auto 5px' }} className="col-1 uaepassor">
                        {translate("web.or", "OR")}
                      </span>
                      <UploadFile
                        text="Upload Singniture Photo"
                        accept="image/*,application/pdf"
                        className="col-5 text-center my-auto"
                        onChange={handleSignitureFileChange}
                      />
                      {imageURL ? (
                        <img
                          className="ownerSigintureImage"
                          src={imageURL}
                          alt="Owner Signature"
                          style={{
                            width: "100%",
                            maxWidth: 200,
                            maxHeight: 50,
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            }
            {isYoungerThan21 === true && userType == 2 &&
              <>
                <h3 className="col-lg-12 mt-2 col-md-12 col-sm-12  rtl">
                  {translate('web.doYourFatherHaveAnAccountOnOurSystem', 'Do Your Father Have An Account On Our System?')}
                </h3>
                <div className="row rtl  col-lg-12 col-md-12 col-sm-12  rtl">
                  <div className="col-lg-3 mt-2 col-md-6 col-sm-12  rtl">
                    <NetaqInput
                      type={"radio"}
                      label={translate("web.yes", "Yes")}
                      name={'fatherHasAccount'}
                      onChange={handleFatherRadioChange}
                      value={true}
                      checked={true}
                    />
                  </div>
                  <div className="col-lg-9 mt-2 col-md-6 col-sm-12  rtl ">
                    <NetaqInput
                      type={"radio"}
                      label={translate("web.no", "No")}
                      name={'fatherHasAccount'}
                      value={false}
                      onChange={handleFatherRadioChange}
                    />
                  </div>
                  {fatherHasAccount == true &&
                    <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl">
                      <NetaqInput
                        type={"text"}
                        label={translate("web.yourFatherRegNumber", "Your Father Reg. Number")}
                        placeholder={translate("web.yourFatherRegNumber", "Your Father Reg. Number")}
                        name={"ParentAccountRegNo"}
                        onChange={handleChange}
                        required={true}
                      />
                    </div>
                  }

                </div>
              </>
            }
            {
              fatherHasAccount == false &&
              <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl form-group row rtl">
                <label className="capatalize_text">
                  {translate('web.yourFathersignature', 'Your Father signature')}<span className="color-eahsred" > *</span>
                </label>
                <div className="col-lg-12 col-md-12 col-sm-12 rtl">
                  <div className="row mx-0 rtl" >
                    <Popup
                      modal
                      lockScroll
                      trigger={
                        <div className="col-5 text-center">
                          <span
                            style={buttonsignatureStyle}
                            onMouseOver={() => handleMouseOver("secondsignature")}
                            onMouseOut={() => handleMouseOut("secondsignature")}
                          >
                            {translate('web.openSigniturePad', 'Open Signiture Pad')}
                          </span>
                        </div>
                      }
                      closeOnDocumentClick={false}
                      closeOnEscape
                    >
                      {(close) => (
                        <>
                          <SignaturePad
                            ref={fatherSigniture}
                            canvasProps={{
                              className: "signatureCanvas",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <button
                              style={buttonSaveStyle}
                              onMouseOver={() => handleMouseOver("save")}
                              onMouseOut={() => handleMouseOut("save")}
                              onClick={() => {
                                onFatherSaveSignature(close);
                              }}
                            >
                              {translate('web.save', 'Save')}
                            </button>
                            <button
                              style={buttonclearStyle}
                              onMouseOver={() => handleMouseOver("clear")}
                              onMouseOut={() => handleMouseOut("clear")}
                              onClick={onFatherClearSignature}
                            >
                              {translate('web.clear', 'clear')}
                            </button>
                            <button
                              style={buttoncloseStyle}
                              onMouseOver={() => handleMouseOver("close")}
                              onMouseOut={() => handleMouseOut("close")}
                              onClick={close}
                            >
                              {translate('web.close', 'close')}
                            </button>
                          </div>
                        </>
                      )}
                    </Popup>
                    <span style={{ margin: 'auto 5px' }} className="col-1 uaepassor">
                      {translate("web.or", "OR")}
                    </span>
                    <UploadFile
                      text="Upload Singniture Photo"
                      className="col-5 text-center my-auto"
                      accept="image/*,application/pdf"
                      onChange={handleFatherFileChange}
                    />
                    {fatherImageUrl ? (
                      <img
                        className="ownerSigintureImage"
                        src={fatherImageUrl}
                        alt="Owner Signature"
                        style={{
                          width: "100%",
                          maxWidth: 200,
                          maxHeight: 50,
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            }
            {isYoungerThan21 === true && userType == 2 && fatherHasAccount == false &&
              <>
                <div className={"col-lg-6 mt-2 col-md-6 col-sm-12  ms-auto rtl form-group"}>
                  <label className="capatalize_text">
                    {translate('web.yourFatherID', 'Your FatherID  Copy')} <span className="color-eahsred" > *</span>
                  </label>
                  <SimpleUpload accept="image/*,application/pdf" isMulti={false} isServer={false} initialFiles={parentPassPortOrNationaIdFile} onChange={parentNationaIdFile} key={'Father'} />
                </div>
              </>
            }

          </div>


          {userType == 4 &&
            <div className="rotate3D mt-3">
              <>
                <h2 className="color-eahsred rotate3D  rtl col-sm-12  ">
                  {translate('web.agentRequestPermisions', 'Agent Requested Permisions')} *
                </h2>
                <GrantedPermissionClaimsComponent
                  permissionsList={permissionsList}
                  toast={toast}
                  agentPermissionClaimsChanged={setAgentPermissionClaims} />
              </>
            </div>
          }
        </div>


        <div className={"col-sm-12 text-right text-left-rtl rtl"}>
          {!!issubmitting ? <Loader /> : <ActionButton
            margin={25}
            width={100}
            key={"Register"}
            text={translate("web.signUp", "Sign Up")}
            type={"submit"}
          />
          }
        </div>
        <ReCAPTCHA sitekey={recaptchaSiteKey}
          size="invisible"
          ref={recaptchaRef}
          onChange={(token) => {
            setRecaptchaToken(token);
            if (token !== "") {
              setRecaptchaVaidate(true)
            }
          }} />
      </form>
      {showOTP &&
        <LoginUaePassOTPForm isRegisterPage={true} setOTP={setOtp} onSubmit={handleOTPSubmit} />
      }
      {showOTP &&
        <div onClick={() => setShowOTP(!showOTP)} style={{ width: '100vw', height: '100vh', position: 'fixed', background: 'rgba(0,0,0,0.3)', top: 0, right: 0, left: 0, bottom: 0 }}></div>
      }
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import NetaqInput from "./NetaqInput";
import ActionButton from "./ActionButton";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

import axios from "./../axios/axios";
import { useTranslate } from "./TranslationText";

import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import {
  GoogleReCaptcha,
  GoogleReCaptchaConsumer,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export default function LoginForm({ showToastmessage }) {
  const navigate = useNavigate();
  const currentlocation = useLocation();
  const { translate } = useTranslate();
  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const [recaptchaToken, setRecaptchaToken] = useState("");

  const [loginCred, setLoginCred] = useState({
    email: "",
    password: "",
    firebaseDeviceId: "",
  });
  const [recaptchaValidate, setRecaptchaVaidate] = useState(false);

  const searchParams = new URLSearchParams(currentlocation.search);
  const redirectURL = searchParams.has("redirectURL")
    ? searchParams.get("redirectURL")
    : null;

  console.debug("currentlocation", currentlocation);

  useEffect(() => {
    if (currentlocation.state === "Error in Login with UAE PASS") {
      showToastmessage(
        currentLanguage == "en"
          ? "User cancelled the login"
          : "قام المستخدم بإلغاء تسجيل الدخول"
      );
    }
  }, []);

  const handleChange = (event) => {
    setLoginCred({
      ...loginCred,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const loginState = await tryLogin(loginCred); // Pass filter state to auctionPage
    if (loginState) {
      debugger;
      if (redirectURL != null) {
        navigate(redirectURL, { state: loginState });
      } else {
        navigate("/profile", { state: loginState });
      }
    }
  };

  async function getUserProfile() {
    const userProfile = await axios.get("/account/profile");
    if (userProfile.data.status.detailedResponseCode !== "Success") {
      localStorage.clear();
      navigate("/", { state: "loggedout" });
      return <Navigate to="/login" />;
    } else {
      localStorage.setItem("EAHSUserID", userProfile.data.result.id);
      localStorage.setItem("currentSubAccountId", userProfile.data.result.id);
      localStorage.setItem(
        "EAHSUserFullName",
        userProfile.data.result.fullName
      );
      const jsonString = JSON.stringify(userProfile.data.result);
      localStorage.setItem("eahsUserProfile", jsonString);
      return true;
    }
  }

  async function tryLogin(loginCred) {
    if (
      loginCred.email == "" ||
      loginCred.password == "" ||
      (loginCred.email == "" && loginCred.password == "")
    ) {
      showToastmessage(
        translate(
          "web.pleaseEnterEmailAndPassword",
          "Please Enter Email And Password"
        )
      );
    } else {
      const response = await axios.post("/account/login", {
        ...loginCred,
        GoogleRecaptchaResponse: recaptchaToken,
      });
      if (response.data?.status?.responseCode === "200") {
        localStorage.setItem("accessTokenforEAHS", response.data.result.token);
        localStorage.setItem("EAHSUserIsUAEPASSUser", false);
        return await getUserProfile();
      } else if (response.data.responseCode == 100) {
        showToastmessage(
          translate(
            // "web.invalidRECAPTCHAPleaseTryAgain.",
            // "Invalid reCAPTCHA. Please try again."
            response.data.responseText,
            response.data.responseText.replace("web.", "")
          )
        );
      } else {
        showToastmessage(
          translate(
            // "web.invaildCredentials"
            response.data.responseText,
            response.data.responseText.replace("web.", "")
          )
        );
        return false;
      }
    }
  }

  const recaptchaRef = useRef();

  useEffect(() => {
    recaptchaRef.current.execute();
  }, []);

  return (
    <>
      {/* <ToastContainer position="top-right" theme="colored" autoClose={5000} /> */}

      <form
        className="right-to-left bg-gray-p-25bg-gray-p-25"
        onSubmit={handleSubmit}
      >
        <h2>{translate("web.login", "Login")}</h2>
        <span>
          {translate("web.donthaveanaccount", "Don’t have an account?")}{" "}
          <Link to={"/register"} className="color-eahsred">
            {translate("web.registerNewAccount", "Register New Account")}
          </Link>{" "}
        </span>
        <br />
        <br />
        <NetaqInput
          name={"email"}
          key={"email"}
          placeholder={translate("web.email", "Email")}
          label={translate("web.email", "Email")}
          type={"email"}
          onChange={handleChange}
        />
        <NetaqInput
          name={"password"}
          key={"password"}
          placeholder={translate("web.password", "password")}
          label={translate("web.password", "password")}
          type={"password"}
          onChange={handleChange}
        />
        <span>
          <Link to={"/forgetpassword"} className="color-eahsred">
            {translate("web.forgetPassword", "Forget Password ?")}
          </Link>
        </span>

        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          size="invisible"
          ref={recaptchaRef}
          onChange={(token) => {
            setRecaptchaToken(token);
            if (token !== "") {
              setRecaptchaVaidate(true);
            }
          }}
        />

        <div className="d-flex-jc-center-fld-col">
          <ActionButton
            margin={0}
            width={"100%"}
            key={"apply"}
            text={translate("web.login", "Login")}
            type={"submit"}
            disabled={!recaptchaValidate}
          />
          <div className="UaePassSeperator">
            <div className="Seperator"></div>
            <span className="uaepassor">{translate("web.or", "OR")}</span>
            <div className="Seperator"></div>
          </div>
          <ActionButton
            margin={5}
            width={"100%"}
            key={"loginuaepass"}
            text={"Sign in With UAE PASS"}
            type={"loginuaepass"}
          />
        </div>
      </form>
    </>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import {
  FetchApproveRejectRequestCoveringCertificatethunk,
  FetchApproveRejectRequestthunk,
  FetchCancelRequestthunk,
  FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk,
  FetchCreateCoveringCertificateServiceRequestthunk,
  FetchCreateEmbryoOocyteTransferPermitServiceRequestthunk,
  FetchCreateOwnerShipTransferServicesRequeststhunk,
  FetchDeleteOwnerShipTransferServicesRequeststhunk,
  FetchGetAllServicesRequestsOwnerToApprovethunk,
  FetchGetAllServicesRequeststhunk,
  FetchGetDocumentTypesByServiceIdthunk,
  FetchGetFilebase64thunk,
  FetchGetFilethunk,
  FetchGetRegisteredHorsesthunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
  FetchIsHaseRequestEmbryoOocyteTransferPermitthunk,
  FetchUpdateCoveringCertificateServiceRequestthunk,
  FetchUpdateEmbryoOocyteTransferPermitServiceRequestthunk,
  FetchUpdateOwnerShipTransferServicesRequeststhunk,
} from "./ServicesRequestsAction";

const initialState = {
  createOwnerShipTransferServicesRequestsData: {},
  createCoveringCertificateServiceRequestData: {},
  createEmbryoOocyteTransferPermitServiceRequestData: {},
  updateOwnerShipTransferServicesRequestsData: {},
  updateCoveringCertificateServiceRequestData: {},
  updateEmbryoOocyteTransferPermitServiceRequestData: {},
  getAllServicesRequestsData: [],
  getServicesRequestByIdData: {},
  getAllServicesRequestsOwnerToApproveData: [],
  getServiceDefinitionByIdData: {},
  servicesRequestsLoading: false,
  servicesRequestsError: null,
  toasterMessageServicesRequests: null,
  deleteOwnerShipTransferServicesRequestsData: null,
  approveRejectRequestData: null,
  approveRejectRequestCoveringCertificateData: null,
  checkLastChildrenOfHorseMareRegLessThanOneWeekData: false,
  isHaseRequestEmbryoOocyteTransferPermitData: {},
  getFileData: null,
  getFilebase64Data: null,
  getDocumentTypesByServiceIdData: [],
  getRegisteredHorsesData: [],
  cancelRequestData: null,
};

const ServicesRequestsSlice = createSlice({
  name: "ServicesRequests",
  initialState,
  reducers: {
    resetToasterMessageServicesRequests: (state) => {
      state.toasterMessageServicesRequests = null;
    },
    resetGetServicesRequestByIdData: (state) => {
      state.getServicesRequestByIdData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      FetchCreateOwnerShipTransferServicesRequeststhunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateOwnerShipTransferServicesRequeststhunk.fulfilled,
      (state, action) => {
        debugger
        state.servicesRequestsLoading = false;
        state.createOwnerShipTransferServicesRequestsData =
          action.payload.result;
        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchCreateOwnerShipTransferServicesRequeststhunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createOwnerShipTransferServicesRequestsData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchGetAllServicesRequeststhunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetAllServicesRequeststhunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getAllServicesRequestsData = action.payload.result;
      }
    );
    builder.addCase(
      FetchGetAllServicesRequeststhunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getAllServicesRequestsData = [];
      }
    );

    builder.addCase(
      FetchGetServicesRequestByIdthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetServicesRequestByIdthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getServicesRequestByIdData = action.payload.result;
      }
    );
    builder.addCase(
      FetchGetServicesRequestByIdthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getServicesRequestByIdData = {};
      }
    );

    builder.addCase(
      FetchDeleteOwnerShipTransferServicesRequeststhunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchDeleteOwnerShipTransferServicesRequeststhunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.deleteOwnerShipTransferServicesRequestsData = action.payload;
        state.toasterMessageServicesRequests = "save";
      }
    );
    builder.addCase(
      FetchDeleteOwnerShipTransferServicesRequeststhunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.deleteOwnerShipTransferServicesRequestsData = null;
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchGetAllServicesRequestsOwnerToApprovethunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetAllServicesRequestsOwnerToApprovethunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getAllServicesRequestsOwnerToApproveData = action.payload.result;
      }
    );
    builder.addCase(
      FetchGetAllServicesRequestsOwnerToApprovethunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getAllServicesRequestsOwnerToApproveData = {};
      }
    );

    builder.addCase(
      FetchUpdateOwnerShipTransferServicesRequeststhunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchUpdateOwnerShipTransferServicesRequeststhunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.updateOwnerShipTransferServicesRequestsData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchUpdateOwnerShipTransferServicesRequeststhunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.updateOwnerShipTransferServicesRequestsData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(FetchApproveRejectRequestthunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
      state.toasterMessageServicesRequests = null;
    });
    builder.addCase(
      FetchApproveRejectRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.approveRejectRequestData = action.payload;
        state.toasterMessageServicesRequests = "save";
      }
    );
    builder.addCase(
      FetchApproveRejectRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.approveRejectRequestData = null;
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchGetServiceDefinitionByIdthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetServiceDefinitionByIdthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getServiceDefinitionByIdData = action.payload;
      }
    );
    builder.addCase(
      FetchGetServiceDefinitionByIdthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getServiceDefinitionByIdData = {};
      }
    );

    builder.addCase(FetchGetFilethunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
    });
    builder.addCase(FetchGetFilethunk.fulfilled, (state, action) => {
      state.servicesRequestsLoading = false;
      state.getFileData = action.payload;
    });
    builder.addCase(FetchGetFilethunk.rejected, (state, action) => {
      state.servicesRequestsLoading = false;
      state.servicesRequestsError = action.payload;
      state.getFileData = null;
    });

    builder.addCase(FetchGetFilebase64thunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
    });
    builder.addCase(FetchGetFilebase64thunk.fulfilled, (state, action) => {
      state.servicesRequestsLoading = false;
      state.getFilebase64Data = action.payload;
    });
    builder.addCase(FetchGetFilebase64thunk.rejected, (state, action) => {
      state.servicesRequestsLoading = false;
      state.servicesRequestsError = action.payload;
      state.getFilebase64Data = null;
    });

    builder.addCase(
      FetchGetDocumentTypesByServiceIdthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetDocumentTypesByServiceIdthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getDocumentTypesByServiceIdData = action.payload.result;
      }
    );
    builder.addCase(
      FetchGetDocumentTypesByServiceIdthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getDocumentTypesByServiceIdData = {};
      }
    );

    builder.addCase(
      FetchGetRegisteredHorsesthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetRegisteredHorsesthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getRegisteredHorsesData =
          action.payload.horsesList;
      }
    );
    builder.addCase(
      FetchGetRegisteredHorsesthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getRegisteredHorsesData = [];
      }
    );

    //covering certificate
    builder.addCase(
      FetchCreateCoveringCertificateServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateCoveringCertificateServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;

        state.createCoveringCertificateServiceRequestData = action.payload.result;

        if (action.payload.result.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.result.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchCreateCoveringCertificateServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createCoveringCertificateServiceRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchUpdateCoveringCertificateServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchUpdateCoveringCertificateServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.updateCoveringCertificateServiceRequestData = action.payload.result;

        debugger
        if (action.payload.result.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.result.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchUpdateCoveringCertificateServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.updateCoveringCertificateServiceRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(FetchApproveRejectRequestCoveringCertificatethunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
      state.toasterMessageServicesRequests = null;
    });
    builder.addCase(
      FetchApproveRejectRequestCoveringCertificatethunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.approveRejectRequestCoveringCertificateData = action.payload;
        state.toasterMessageServicesRequests = "save";
      }
    );
    builder.addCase(
      FetchApproveRejectRequestCoveringCertificatethunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.approveRejectRequestCoveringCertificateData = null;
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchCancelRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCancelRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.cancelRequestData = action.payload;
        state.toasterMessageServicesRequests = "save";
      }
    );
    builder.addCase(
      FetchCancelRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.cancelRequestData = null;
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
    });
    builder.addCase(
      FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.checkLastChildrenOfHorseMareRegLessThanOneWeekData = action.payload;
      }
    );
    builder.addCase(
      FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.checkLastChildrenOfHorseMareRegLessThanOneWeekData = false;
      }
    );

    //Embryo Oocyte Transfer Permit
    builder.addCase(
      FetchCreateEmbryoOocyteTransferPermitServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateEmbryoOocyteTransferPermitServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.createEmbryoOocyteTransferPermitServiceRequestData =
          action.payload.result;
        state.toasterMessageServicesRequests = "save";
      }
    );
    builder.addCase(
      FetchCreateEmbryoOocyteTransferPermitServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createEmbryoOocyteTransferPermitServiceRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchUpdateEmbryoOocyteTransferPermitServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchUpdateEmbryoOocyteTransferPermitServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.createEmbryoOocyteTransferPermitServiceRequestData =
          action.payload.result;
        state.toasterMessageServicesRequests = "save";
      }
    );
    builder.addCase(
      FetchUpdateEmbryoOocyteTransferPermitServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createEmbryoOocyteTransferPermitServiceRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(FetchIsHaseRequestEmbryoOocyteTransferPermitthunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
    });
    builder.addCase(
      FetchIsHaseRequestEmbryoOocyteTransferPermitthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.isHaseRequestEmbryoOocyteTransferPermitData = action.payload;
      }
    );
    builder.addCase(
      FetchIsHaseRequestEmbryoOocyteTransferPermitthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.isHaseRequestEmbryoOocyteTransferPermitData = {};
      }
    );
  },
});

export const {
  resetToasterMessageServicesRequests,
  resetGetServicesRequestByIdData,
} = ServicesRequestsSlice.actions;
export default ServicesRequestsSlice.reducer;

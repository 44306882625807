import React, { useState, useEffect, useRef } from "react";
import { useTranslate } from "./TranslationText";

const FileUpload = ({
  isMulti,
  isServer = false,
  onChange,
  accept,
  disabled = false,
  className = "",
  initialFiles = [],
}) => {
  //const [uploadedFiles, setUploadedFiles] = useState([]);
  const { translate } = useTranslate();
  const [serverFiles, setServerFiles] = useState(initialFiles);
  const inputRef = useRef(null);

  useEffect(() => {
    // Load initial files (if provided)
    setServerFiles(initialFiles);
  }, [initialFiles]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setServerFiles(files);
    //setUploadedFiles(files);
  };

  const handleUploadClick = () => {
    if (inputRef.current && !disabled) {
      inputRef.current.click();
      //document.getElementById("file-input").click();
    }
  };

  const handleFileClick = (file, isFromServer = false) => {
    if (isFromServer) {
      // Open the server-stored file using its URL
      window.open(file.file, "_blank", "noopener,noreferrer");
    } else {
      // Open the newly uploaded file using a blob URL
      const fileURL = URL.createObjectURL(file.file);
      window.open(fileURL, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <div>
      <div className="input-group">
        {/* Display both server and newly uploaded file names */}
        <input
          type="text"
          className="form-control"
          placeholder={translate("web.noFileSelected", "No file selected")}
          style={{ cursor: "pointer", height: 37.6 }}
          value={
            serverFiles.length > 0
              ? [
                ...serverFiles.map((file) => file.name),
                //...uploadedFiles.map((file) => file.name),
              ].join(",")
              : serverFiles.length === 1
                ? serverFiles[0].name
                : ""
          }
          readOnly
          onClick={() => {
            if (serverFiles.length === 1) {
              if (isServer) {
                handleFileClick(serverFiles[0], true);
              } else {
                handleFileClick(serverFiles[0], false);
              }
            } else if (serverFiles.length > 1) {
              if (isServer) {
                serverFiles.forEach((file) => handleFileClick(file, true));
              } else {
                serverFiles.forEach((file) => handleFileClick(file, false));
              }
            }
          }}
        />
        <div
          style={{
            cursor: disabled ? "not-allowed" : 'pointer',
            opacity: disabled && 0.65,

          }}
          className="input-group-text bg-white text-danger"
          id="basic-addon2"
          onClick={handleUploadClick}
        >
          {translate("web.uploadFile", "Upload File")}
        </div>
      </div>

      {/* Hidden file input field */}
      <input
        id="file-input"
        type="file"
        multiple={isMulti}
        style={{ display: "none" }}
        onChange={onChange}
        accept={accept}
        ref={inputRef}
      //onChange={handleFileChange}
      />
    </div>
  );
};

export default FileUpload;

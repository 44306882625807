import React, { useEffect, useRef, useState } from "react";
import axios from "../axios/axiosUmbraco";
import { useSelector } from "react-redux";
import styled from 'styled-components';
import YouTubePlayer from "./YouTubePlayer";
import { Link } from "react-router-dom";
const BaseURL = process.env.REACT_APP_BASE_URL;
const fetch = process.env.REACT_APP_HOME_PAGE_FIRST_SECTION_ID;
const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;



export default function VideoSlider() {
  const videoRef = useRef();
  const { currentLanguage } = useSelector(
    (state) => state.studBookSlice
  );
  const [sliderItems, setSliderItems] = useState([{
    name: "",
    image: '',
    isExternalLink: false,
    sliderLink: '',
    isImage: true,
    subTitle: "",
    title: "", videoLink: ''
  }]);
  const [total, setTotal] = useState(0);
  const [yvideoID, setvideoID] = useState('')

  function convertYouTubeURL(inputURL) {
    // Regular expression to extract the video ID
    var regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    // Extract video ID from input URL
    var match = inputURL.match(regex);
    if (!match || !match[1]) {
      return "Invalid YouTube URL";
    }

    // Construct the output URL with desired parameters
    var videoID = match[1];
    // var outputURL = "https://www.youtube.com/embed/" + videoID + "?autoplay=1&controls=1&loop=1&mute=1&playlist=" + videoID;
    // setvideoID(videoID);
    return videoID;
  }
  function getCurrentLanguageUmbraco() {
    // Replace this with your logic to retrieve the current language
    // For example, you can retrieve it from localStorage or from a state variable
    const lang = localStorage.getItem("eahsLang") ?? "en";
    if (lang == "en") return 'en-US';
    else return 'ar-AE'; // Defaulting to English for demonstration
  }

  useEffect(() => {
    async function getdata() {
      const umbraco = await axios.get(`/umbraco/delivery/api/v1/content?fetch=${fetch}&skip=0&take=10`, {
        headers: {
          "accept-language": getCurrentLanguageUmbraco(),

        }
      })
      const formattedData = umbraco.data?.items.map((item) => ({
        name: item.name,
        image: item.properties?.image ? item.properties?.image[0]?.url : '',
        isExternalLink: item.properties?.isExternalLink ? true : false,
        sliderLink: item.properties?.sliderLink ? item.properties?.sliderLink : '',
        isImage: item.properties?.sliderType ? true : false,
        subTitle: item.properties?.subTitle ? item.properties?.subTitle : '',
        sort: item.properties?.sort ? item.properties?.sort : 1,
        title: item.properties?.title ? item.properties?.title : '',
        videoLink: item.properties?.videoLink ? convertYouTubeURL(item.properties?.videoLink) : ''
      }));
      setTotal(umbraco.data?.total)
      const sortedData = formattedData.sort((a, b) => a.sort - b.sort);

      setSliderItems(sortedData);
    }
    getdata();

  }, [currentLanguage])



  return (

    <div>
      {total > 0 &&
        <div id="video_section" className="carousel slide" data-ride="carousel" data-bs-ride="carousel">
          {total > 1 &&
            <>
              <ol className="carousel-indicators video_section_class ">
                {Array.from({ length: total }).map((_, index) => (
                  <li
                    key={index}
                    data-target="#video_section"
                    data-bs-target="#video_section"
                    data-slide-to={index}
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                  ></li>
                ))}
              </ol>
              {/* <div class="carousel-indicators video_section_class justRotate">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div> */}
            </>
          }


          <div className="carousel-inner">
            {sliderItems.map((item, index) => (
              <div className={index === 0 ? "carousel-item item active" : "carousel-item item"}>
                {/* check if it is video or Image item */}

                {item.isImage ?
                  <>
                    {/* image */}
                    {item.sliderLink == '' ?
                      <div className="video_container container">
                        <img
                          // className="first_slider_img"
                          autoPlay
                          alt="slider"
                          muted
                          src={`${MediaBaseURL}${item.image}`}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = `${BaseURL}/assets/img/horseauction.png`;
                          }}
                          style={{width:'100%'}}
                        />
                      </div>
                      :
                      <Link className="video_container container" to={item.sliderLink} target={item.isExternalLink ? "_blank" : '_self'}>
                        <img
                          // className="first_slider_img"
                          autoPlay
                          alt="slider"
                          muted
                          src={`${MediaBaseURL}${item.image}`}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = `${BaseURL}/assets/img/horseauction.png`;
                          }}
                          style={{width:'100%'}}
                        />
                      </Link>
                    }

                    {/* <div className="video_overlay"></div> */}
                    <div className="carousel-caption first_slider_caption">
                      {item.sliderLink == '' ?
                        <>
                          <h3 className="video_title font-64 color-eahsred">
                            {item.title}
                          </h3>
                          <p className="video_subtitle font-24 white-icon fontweight600">
                            {item.subTitle}
                          </p>
                        </>
                        :
                        <a href={item.sliderLink} target={item.isExternalLink ? "_blank" : '_self'}>
                          <h3 className="video_title font-64 color-eahsred">
                            {item.title}
                          </h3>
                          <p className="video_subtitle font-24 white-icon fontweight600">
                            {item.subTitle}
                          </p>
                        </a>}


                      <br />
                      <br />
                    </div>
                  </>
                  :
                  <>
                    {/* video */}
                    <div className="video_container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {/* <iframe
                        ref={videoRef}
                        className="video_player transformScale1-4"
                        title="YouTube video player"
                        width="100%"
                        src={item.videoLink}
                        frameBorder="0"
                      ></iframe> */}
                      {!!item.videoLink &&
                        <YouTubePlayer key={item.videoLink} videoId={item.videoLink} />
                      }

                    </div>

                    {/* <div className="video_overlay"></div> */}
                    <div className="carousel-caption first_slider_caption">
                      {item.sliderLink == '' ?
                        <>
                          <h3
                            className="video_title font-64 color-eahsred"
                          >
                            {item.title}
                          </h3>
                          <p
                            className="video_subtitle font-24 white-icon fontweight600"
                          >
                            {item.subTitle}
                          </p>
                        </> :
                        <>
                          <a href={item.sliderLink} target={item.isExternalLink ? "_blank" : '_self'}>
                            <h3
                              className="video_title font-64 color-eahsred"
                            >
                              {item.title}
                            </h3>
                            <p
                              className="video_subtitle font-24 white-icon fontweight600"
                            >
                              {item.subTitle}
                            </p>
                          </a>
                        </>
                      }


                      <br />
                      <br />
                    </div>
                  </>
                  // <>
                  //      {!!yvideoID &&
                  //        <YouTubePlayer isSecondOption={true} videoId={yvideoID} item={item} />
                  //      }
                  // </>
                }

              </div>
            ))}
          </div>
        </div>
      }
    </div>

  );
}
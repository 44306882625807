import React, { useEffect, useRef, useState } from 'react'
import NetaqInput from '../components/NetaqInput'
import ChampionsDynamicTable from '../components/ChampionsDynamicTable';
import ShowCategoryDynamicTable from '../components/ShowCategoryDynamicTable';
import ShowPrizeDynamicTable from '../components/ShowPrizeDynamicTable';
import ActionButton from '../components/ActionButton';
import { useTranslate } from '../components/TranslationText';
import axiosInstance from '../axios/axios';
import { GuidGenerator } from '../helper/GuidGenerator';
import dayjs from 'dayjs'; // Import dayjs
import AsyncSelect from 'react-select/async';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router';
import { convertToCamelCase } from '../helper/convertToCamelCase';
import { addUtcOffset } from '../helper/addUtcOffset';
import { normalDateFormat } from '../helper/normalDateFormat';


export default function AddOrganizerRequest() {
    const { translate } = useTranslate();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const isEdit = searchParams.has("isEdit")
        ? true
        : false;

    const requestId = searchParams.has("requestId")
        ? searchParams.get("requestId")
        : "";

    const [oldEditValues, setOldEditValues] = useState({});

    async function getRequestByID(params) {
        try {
            const draftRequest = await axiosInstance.get(`shows/getshoworganizerequestbyid?id=${requestId}`)
            if (draftRequest.data.responseCode == "200") {
                setOldEditValues(draftRequest.data.result);

                setSelectShow(availableShows.filter((x) => { return x.value == draftRequest.data.result.showId }))
                setShowRank(rank.filter((x) => { return x.value == draftRequest.data.result.showRankId }))
                setShowLocation(availableCities.filter((x) => { return x.value == draftRequest.data.result.emirateId }))
                setShowStartDate(normalDateFormat(draftRequest.data.result.startDate))
                setShowEndDate(normalDateFormat(draftRequest.data.result.endDate))
                setRegisterStartDate(normalDateFormat(draftRequest.data.result.registerStartDate))
                setRegisterEndDate(normalDateFormat(draftRequest.data.result.registerEndDate))
                setActivateTermsAndConditions(draftRequest.data.result.haveTerms)
                const draftchampions = draftRequest.data.result.championShips.map((champ) => ({
                    col1: champ.name,
                    col2: champ.nameAr,
                    col3: champ.gender,
                    col4: champ.rank,
                    customGuid: champ.id
                }));

                setChampoinsRows(draftchampions);
                const draftcategories = draftRequest.data.result.categories.map((cat) => ({
                    col1: cat.name,
                    col2: cat.nameAr,
                    col3: 20,
                    col4: parseInt(cat.maxNumberOfClasses),
                    col5: normalDateFormat (cat.birthDateFrom),
                    col6: normalDateFormat(cat.birthDateTo),
                    col8: cat.championshipId,
                    customGuid: cat.id
                }));
                setshowsCategoriesRows(draftcategories)
                const draftPrize = draftRequest.data.result.prizeList.map((prize) => ({
                    col1: prize.stage == "Classes" ? "Classes" : "Champions",
                    col2: prize.stage == "Classes" ? prize.selectedCategories : prize.selectedChampions,
                    col3: prize.fromRank,
                    col4: prize.toRank,
                    col5: prize.prizeValue,
                    customGuid: prize.id
                }));
                setShowPrizeRows(draftPrize);
                const noPaymentOwnersDraft = draftRequest.data.result.noPaymentOwners.map((owner) => ({
                    label: owner,
                    value: owner,
                    text: owner
                }))
                setNoPaymentOwners(noPaymentOwnersDraft);
            }
            console.debug('current request is', draftRequest)

        } catch (error) {

        }
    }

    console.debug('ss', oldEditValues)
    const [showsLoaded, setShowsLoaded] = useState(false);
    const [citiesLoaded, setCitiesLoaded] = useState(false);



    const [registerStartDate, setRegisterStartDate] = useState(null)
    const [registerEndDate, setRegisterEndDate] = useState(null)
    const [showStartDate, setShowStartDate] = useState(null)
    const [showEndDate, setShowEndDate] = useState(null)
    // Initial state for the table rows
    const [champoinsRows, setChampoinsRows] = useState([
        { col1: "", col2: "", col3: "", col4: "", customGuid: GuidGenerator() },
    ]);

    // Initial state for the table rows
    const [showsCategoriesRows, setshowsCategoriesRows] = useState([
        { col1: "", col2: "", col3: "", col4: "", col5: "", col6: "", col8: "", customGuid: GuidGenerator() },
    ]);

    const [showPrizeRows, setShowPrizeRows] = useState([
        { col1: "", col2: [], col3: "", col4: "", col5: "", customGuid: GuidGenerator() },
    ]);

    const [formData, setFormData] = useState({});

    const [termsAndConditionsEN, setTermsAndConditionsEN] = useState('');
    const [termsAndConditionsAR, setTermsAndConditionsAR] = useState('');
    const [activateTermsAndConditions, setActivateTermsAndConditions] = useState(false);
    const [showRank, setShowRank] = useState(null)
    const [showLocation, setShowLocation] = useState(null)
    const [selectShow, setSelectShow] = useState(null)
    const [firstTerm, setFirstTerm] = useState(false);
    const [secondTerm, setsecondTerm] = useState(false);
    const [thirdTerm, setthirdTerm] = useState(false);
    const [fourthTerm, setfourthTerm] = useState(false);

    const [availableShows, setAvailableShows] = useState([]);
    const [availableCities, setAvailableCities] = useState([]);
    const rank = [{
        text: 'B. Nat',
        label: 'B. Nat',
        name: 'B. Nat',
        value: '6a875ca3-da01-4eb8-a9b1-0e1aea4c4f05'
    }, {
        text: 'C. Nat',
        label: 'C. Nat',
        name: 'C. Nat',
        value: 'c4d4aa32-b4b8-441f-84e2-3688bb87d5c6'
    }, {
        name: 'B. Int',
        label: 'B. Int',
        text: 'B. Int',
        value: '112e8fcc-3fb8-4ac2-b5be-5560e25487d6'
    },
    {
        name: 'C. Int',
        text: 'C. Int',
        label: 'C. Int',
        value: '261393bc-e16d-4ecf-9d6c-7b1b5e1fa83d'
    },
    {
        name: 'N. Nat',
        label: 'N. Nat',
        text: 'N. Nat',
        value: 'fbbc02e2-51a8-4dea-ac40-9c221f52f73a'
    },
    {
        name: 'Title',
        label: 'Title',
        text: 'Title',
        value: '3206e40f-46e2-4e29-9aa9-b05f0f07f080'
    },
    {
        name: 'A. Int',
        label: 'A. Int',
        text: 'A. Int',
        value: '77f856bb-f47a-4e97-8bd0-e2c5537761af'
    },
    {
        name: 'Sp. Org',
        label: 'Sp. Org',
        text: 'Sp. Org',
        value: 'b285582f-21dd-47fc-86b8-eb3351bc1147'
    },

    ];

    const genderList = [{
        text: translate('web.mare', 'mare'),
        name: translate('web.mare', 'mare'),
        value: 'mare'
    }, {
        text: translate('web.stallion', 'Stallion'),
        name: translate('web.stallion', 'Stallion'),
        value: 'stallion'
    }, {
        text: translate('web.femaleOrGelding', 'Female Or Gelding'),
        name: translate('web.femaleOrGelding', 'Female Or Gelding'),
        value: 'FemaleOrGelding'
    }];
    const stageList = [{
        text: translate('web.theClasses', 'Classes'),
        name: translate('web.theClasses', 'Classes'),
        value: 'Classes'
    }, {
        text: translate('web.champions', 'Champions'),
        name: translate('web.champions', 'Champions'),
        value: 'Champions'
    }];

    async function getUpcomingShows() {
        try {
            const upcomingShows = await axiosInstance.get('Shows/GetUpcomingShowForOthers')
            if (upcomingShows.data.responseCode == "200") {
                const formattedShows = upcomingShows.data.result.map((show) => ({
                    text: show.showName,
                    label: show.showName,
                    value: show.id,
                    showRank: show.showRank,
                    showRankId: show.showRankId,
                    emirateId: show.emirateId,
                    showStartDate: show.startDate,
                    showEndDate: show.endDate,
                    registerStartDate: show.registerStartDate,
                    registerEndDate: show.registerEndDate,

                }));
                setAvailableShows(formattedShows)
                setShowsLoaded(true)
            }
        } catch (error) {
            console.log('error in getting shows');
        }
    }

    async function getCities() {
        try {
            const cities = await axiosInstance.get('Lookup/GetCities');
            if (cities.data.responseCode == "200") {
                const formattedCities = cities.data.result.map((city) => ({
                    text: city.name,
                    label: city.name,
                    value: city.id
                }));
                setAvailableCities(formattedCities)
                setCitiesLoaded(true)
            }
        } catch (error) {
            console.log('error in getting emirates', error)
        }
    }
    useEffect(() => {
        getUpcomingShows();
        getCities();
    }, [])

    useEffect(() => {
        if (isEdit && showsLoaded && citiesLoaded) {
            getRequestByID();
        }
    }, [showsLoaded, citiesLoaded])

    const handleInputChange = (e, isDropDown = false, inputName) => {
        if (!isDropDown) {
            const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value
            });
        } else {
            if (inputName == 'selectShow') {
                setSelectShow({
                    text: e.label,
                    label: e.label,
                    value: e.value,
                })
                setShowRank({
                    text: e.showRank,
                    label: e.showRank,
                    value: e.showRankId,
                })

                setShowStartDate(normalDateFormat(e.showStartDate))
                setShowEndDate(normalDateFormat(e.showEndDate))
                setRegisterStartDate(normalDateFormat(e.registerStartDate))
                setRegisterEndDate(normalDateFormat(e.registerEndDate))
                const selectedLocation = availableCities.filter((x) => x.value == e.emirateId)
                if (selectedLocation.length > 0) {
                    setShowLocation(selectedLocation[0])
                    setFormData({
                        ...formData,
                        showLocation: selectedLocation[0]
                    });
                }
                setFormData({
                    ...formData,
                    [inputName]: e.value,
                    selectRank: e.showRankId
                });
                return;
            }
            if (inputName == "selectRank") {
                setShowRank({
                    text: e.label,
                    label: e.label,
                    value: e.value,
                })
                setFormData({
                    ...formData,
                    [inputName]: e.value
                });
                return;
            }
            if (inputName == "showLocation") {
                setShowLocation({
                    text: e.label,
                    label: e.label,
                    value: e.value,
                })
                setFormData({
                    ...formData,
                    [inputName]: e.value
                });
                return;
            }

            setFormData({
                ...formData,
                [inputName]: e.value
            });
        }
    };
    // terms and Conditions Statics
    const handlereset = () => {
        setSelectShow(null);
        setfourthTerm(false)
        setthirdTerm(false)
        setsecondTerm(false)
        setFirstTerm(false)
        setTermsAndConditionsEN('')
        setTermsAndConditionsAR('')
        setShowLocation(null)
        setShowRank(null)
        setActivateTermsAndConditions(false);
    }
    function isEmpty(value) {
        return value === null || value === undefined || value === '';
    }
    function validateSentDetails(details) {
        // Check all properties of details
        for (const [key, value] of Object.entries(details)) {
            // Skip validation for 'termsAndConditionsAR' and 'termsAndConditionsEn'
            if (key === 'termsAndConditionsAR' || key === 'termsAndConditionsEn' || key === 'notes') {
                continue;
            }

            // Check the 'categories' array
            if (key === 'categories') {
                value.forEach((category, index) => {
                    // Loop through each category's properties and check for empty values
                    for (const [catKey, catValue] of Object.entries(category)) {
                        if (isEmpty(catValue)) {
                            toast.error(translate(`web.theField`, `The field`) + " " + translate(`web.${(catKey)}`, catKey) + " " + translate(`web.isEmptyIn`, 'is empty in') + " " + translate('web.category', 'category') + " " + translate('web.atIndex', 'at Index') + `  ${index + 1}`)
                            throw new Error(`Error: The field '${catKey}' is empty in category at index ${index}.`);
                        }
                    }
                });
            } else if (key === 'championShips') {
                value.forEach((category, index) => {
                    // Loop through each category's properties and check for empty values
                    for (const [catKey, catValue] of Object.entries(category)) {
                        if (isEmpty(catValue)) {
                            toast.error(translate(`web.theField`, `The field`) + " " + translate(`web.${(catKey)}`, catKey) + " " + translate(`web.isEmptyIn`, 'is empty in') + " " + translate('web.championships', 'championShips') + " " + translate('web.atIndex', 'at Index') + `  ${index + 1}`)
                            throw new Error(`Error: The field '${catKey}' is empty in championShips at index ${index}.`);
                        }
                    }
                });
            } else if (key === 'prizeList') {
                value.forEach((category, index) => {
                    // Loop through each category's properties and check for empty values
                    for (const [catKey, catValue] of Object.entries(category)) {
                        if (isEmpty(catValue)) {
                            toast.error(translate(`web.theField`, `The field`) + " " + translate(`web.${(catKey)}`, catKey) + " " + translate(`web.isEmptyIn`, 'is empty in') + " " + translate('web.prizeList', 'Prize List') + " " + translate('web.atIndex', 'at Index') + `  ${index + 1}`)
                            throw new Error(`Error: The field '${catKey}' is empty in prizeList at index ${index}.`);
                        }
                    }
                });
            } else if (isEmpty(value)) {
                debugger;
                // For other fields, check if the value is empty
                toast.error(translate(`web.theField`, `The field`) + " " + translate(`web.${(key)}`, key) + " " + translate(`web.isEmpty`, 'is empty ') + " " + translate('web.orInvalid', 'orInvalid'))
                throw new Error(`Error: The field '${key}' is empty or invalid.`);
            }
        }
        return true;

    }
    async function handleSubmit(e, isDraft = false) {
        e.preventDefault();
        console.debug("mapLocation:", formData.mapLocation, oldEditValues.mapLocation);
        try {
            const sendedDetails = {
                id: isEdit ? oldEditValues.id : '00000000-0000-0000-0000-000000000000',
                showId: formData?.selectShow ?? oldEditValues.showId,
                showRankId: showRank.value ?? oldEditValues.showRankId,
                emirateId: showLocation.value ?? oldEditValues.emirateId,
                entryFees: formData.entryFees ?? oldEditValues.entryFees,
                notes: formData.notes ?? oldEditValues.notes,
                maxHorseNumberPerOwner: parseInt(formData.maxNoOfHorsesPerOwner) ?? oldEditValues.maxHorseNumberPerOwner,
                address: formData.address ?? oldEditValues.address,
                mapLocation: formData.mapLocation ?? oldEditValues.mapLocation,
                status: isDraft ? "Draft" : "Pending",
                startDate: showStartDate,
                endDate: showEndDate,
                registerStartDate: registerStartDate,
                registerEndDate: registerEndDate,
                haveTerms: activateTermsAndConditions,
                termsAndConditionsEn: termsAndConditionsEN,
                termsAndConditionsAR: termsAndConditionsAR,
                useEAHSScoringSystem: firstTerm,
                ecahcoJsonFormat: secondTerm,
                amendmentHorseList: thirdTerm,
                sendFinalList: fourthTerm,
                noPaymentOwners: noPaymentOwners.map((owner) => (owner.label)),
                categories: showsCategoriesRows.map((category) => ({
                    id: category.customGuid,
                    name: category.col1,
                    nameAr: category.col2,
                    maxHorsesPerClass: 20,
                    maxNumberOfClasses: category.col4,
                    birthDateFrom: category.col5,
                    birthDateTo: category.col6,
                    championshipId: category.col8,
                    isDeleted: false
                })),
                championShips: champoinsRows.map((champ) => ({
                    id: champ.customGuid,
                    name: champ.col1,
                    nameAr: champ.col2,
                    gender: champ.col3,
                    rank: champ.col4,
                })),
                prizeList: showPrizeRows.map((prize) => ({
                    id: prize.customGuid,
                    prizeValue: prize.col5,
                    stage: prize.col1,
                    fromRank: prize.col3,
                    toRank: prize.col4,
                    selectedCategories: prize.col1 == 'Classes' ? prize.col2 : [],
                    selectedChampions: prize.col1 == 'Champions' ? prize.col2 : []
                }))
            };
            debugger;

            // Validate sendedDetails object
            const isValid = validateSentDetails(sendedDetails);
            if (isValid == false) {
                return
            }
            const sendPostRequest = await axiosInstance.post(`shows/${isEdit ? 'EditShowOrganizeRequest' : 'CreateShowOrganizeRequest'}`, {
                ...sendedDetails
            });

            if (sendPostRequest.data.responseCode == "200" && !isDraft) {
                toast.success(translate('web.requestSentSuccesfully', 'Request Sent Succesfully'))
                setTimeout(() => {
                    navigate('/profile')
                }, 3000);
            } else if (sendPostRequest.data.responseCode != "200" && !isDraft) {
                toast.error(translate('web.requestFailed', 'Request Failed'))
            } else if (sendPostRequest.data.responseCode == "200" && isDraft) {
                toast.warn(translate('web.requestSavedAsDraft', 'Request Saved As Draft'))
                setTimeout(() => {
                    navigate('/profile')
                }, 3000);
            } else {
                toast.error(translate('web.requestFailed', 'Request Failed'))
            }
            // If validation passes, continue with your logic
            console.log("All values are valid. Proceeding...");
        } catch (error) {
            // toast.error(translate('web.pleaseCheckAllFields', 'Please Check All Fields'))
            console.debug('my error is', error.message)
            // console.error(error.message);
            // Stop execution or handle it according to your requirements
        }



    };
    const [inputValue, setInputValue] = useState(null);
    const [timeoutId, setTimeoutId] = useState(0);
    const [noPaymentOwners, setNoPaymentOwners] = useState([])

    const promiseOptions = (searchKeyword) =>
        new Promise((resolve) => {
            if (timeoutId > 0) {
                clearTimeout(timeoutId);
            }
            const id = setTimeout(async () => {
                const response = await axiosInstance.post('/StudBook/FilterOwners', { langauge: localStorage.getItem('eahsLang') ?? 'en', ownerId: searchKeyword, skip: 0, take: 10000 })
                const data = await response.data.ownersList;
                const options = data.map((item) => ({
                    label: item.ownerId + " - " + item.name,
                    value: item.ownerId,
                }));
                resolve(options);
            }, 1000);

            setTimeoutId(id);
        });

    const dismissAll = () => toast.dismiss();

    return (
        <>
            <ToastContainer position="top-right" onClick={dismissAll} theme="colored" autoClose={10000} />
            <div className='container'>
                <h2 className='rtl text-danger' > {translate('web.addNewRequest', 'Add New Request')}</h2>
                <form className='row rtl' onReset={handlereset} onSubmit={handleSubmit} >
                    <div className='col-lg-6 col-md-6 col-sm-12' >
                        <NetaqInput
                            onChange={(e) => handleInputChange(e, true, 'selectShow')}
                            type={'select'}
                            listSelect={availableShows}
                            label={translate('web.show', 'show')}
                            name={'selectShow'}
                            required={true}
                            value={selectShow}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12' >
                        <NetaqInput
                            onChange={(e) => handleInputChange(e, true, 'selectRank')}
                            type={'select'}
                            listSelect={rank}
                            label={translate('web.showrank', 'Rank')}
                            value={showRank}
                            name={'selectRank'}
                            required={true}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12' >
                        <NetaqInput
                            onChange={(e) => handleInputChange(e, true, 'showLocation')}
                            type={'select'} listSelect={availableCities}
                            label={translate('web.showLocation', 'Show Location')}
                            value={showLocation}
                            name={'showLocation'}
                            required={true}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12' >
                        <NetaqInput
                            onChange={handleInputChange}
                            type={'number'}
                            label={translate('web.entryFees', 'Entry Fees') + " " + translate('web.inAED', 'in AED') + " (" + translate('web.includingVAT', "Including VAT") + ")"}
                            name={'entryFees'}
                            value={oldEditValues.entryFees}
                            required={true}
                        />
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6' >
                        <NetaqInput
                            type={'normalDate'}
                            label={translate('web.showStartDate', 'Show Start Date')}
                            name={'showStartDate'}
                            value={showStartDate}
                            onChange={(e) => setShowStartDate(e.target.value)}
                            required={true}
                        />
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6' >
                        <NetaqInput
                            type={'normalDate'}
                            label={translate('web.showEndDate', 'Register End Date')}
                            name={'showEndDate'}
                            minDate={showStartDate}
                            value={showEndDate}
                            onChange={(e) => setShowEndDate(e.target.value)}
                            required={true}
                        />
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6' >
                        <NetaqInput
                            type={'normalDate'}
                            label={translate('web.registerStartDate', 'Register Start Date')}
                            name={'registerStartDate'}
                            value={registerStartDate}
                            onChange={(e) => setRegisterStartDate(e.target.value)}
                            required={true}
                        />
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6' >
                        <NetaqInput
                            type={'normalDate'}
                            label={translate('web.registerEndDate', 'Register End Date')}
                            value={registerEndDate}
                            onChange={(e)=>setRegisterEndDate(e.target.value)}
                            name={'registerEndDate'}
                            required={true}
                        />
                    </div>



                    <div className='col-lg-6 col-md-6 col-sm-12' >
                        <NetaqInput
                            onChange={handleInputChange}
                            type={'orginisertext'}
                            value={oldEditValues?.address}
                            label={translate('web.address', 'Address')}
                            name={'address'}
                            required={true}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12' >
                        <NetaqInput
                            onChange={handleInputChange}
                            type={'orginisertext'}
                            value={oldEditValues?.mapLocation}
                            label={translate('web.mapLocation', 'Map Location')}
                            name={'mapLocation'}
                            required={true}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12' >
                        <NetaqInput
                            onChange={handleInputChange}
                            type={'number'}
                            label={translate('web.maxNoOfHorsesPerOwner', 'Max No Of Horses per Owner')}
                            name={'maxNoOfHorsesPerOwner'}
                            required={true}
                            value={oldEditValues?.maxHorseNumberPerOwner}
                        />
                    </div>

                    <h3 className='text-danger mt-3'>{translate('web.doYouHaveTermsAndConditions', 'Do You Have Terms And Conditions')}</h3>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                        <NetaqInput
                            type={'radio'}
                            label={translate('web.yes', 'Yes')}
                            name={'termsandconditionsactive'}
                            onChange={() => setActivateTermsAndConditions(true)}
                            required={true}
                        />

                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                        <NetaqInput
                            type={'radio'}
                            label={translate('web.no', 'No')}
                            name={'termsandconditionsactive'}
                            checked={!activateTermsAndConditions}
                            onChange={() => setActivateTermsAndConditions(false)}
                            required={true}
                        />
                    </div>
                    <div className='col-lg-12 col-sm-12' >&nbsp;</div>
                    {activateTermsAndConditions &&
                        <>
                            <div className='col-lg-6 col-lg-6 col-sm-12 mt-4 ' >
                                <NetaqInput
                                    onChange={setTermsAndConditionsEN}
                                    type={'htmlEditor'}
                                    label={translate('web.termsAndConditionsEN', 'Terms And Conditions (EN)')}
                                    name={'termsAndConditionsEN'}
                                    required={true}
                                    initialValue={oldEditValues?.termsAndConditionsEn}
                                />
                            </div>
                            <div className='col-lg-6 col-lg-6 col-sm-12 mt-4 ' >
                                <NetaqInput
                                    onChange={setTermsAndConditionsAR}
                                    type={'htmlEditor'}
                                    label={translate('web.termsAndConditionsAR', 'Terms And Conditions (AR)')}
                                    name={'termsAndConditionsAR'}
                                    required={true}
                                    initialValue={oldEditValues?.termsAndConditionsAr}
                                />
                            </div>
                        </>
                    }

                    <h3 className='text-danger mt-3'>{translate('web.championships', 'Championships')}</h3>

                    <div className='col-lg-12 col-md-12 col-sm-12 mt-3' >
                        <ChampionsDynamicTable
                            rows={champoinsRows}
                            setRows={setChampoinsRows}
                            genderList={genderList}
                            rankList={rank}
                        />
                    </div>
                    <h3 className='text-danger mt-4'>{translate('web.categories', 'Categories')}</h3>

                    <div className='col-lg-12 col-md-12 col-sm-12 mt-3' >
                        <ShowCategoryDynamicTable
                            rows={showsCategoriesRows}
                            setRows={setshowsCategoriesRows}
                            champoinsRows={champoinsRows}
                            genderList={genderList}
                        />
                    </div>
                    <h3 className='text-danger mt-4'>{translate('web.prizeList', 'Prize List')}</h3>

                    <div className='col-lg-12 col-md-12 col-sm-12 mt-3' >
                        <ShowPrizeDynamicTable
                            rows={showPrizeRows}
                            setRows={setShowPrizeRows}
                            stageList={stageList}
                            champoinsRows={champoinsRows}
                            showsCategoriesRows={showsCategoriesRows}
                            isEdit={isEdit}
                        />
                    </div>

                    <h3 className='text-danger mt-3'>{translate('web.chooseNoPaymentOwners', 'Choose No Payment Owners')}</h3>
                    <div className="col-lg-12 col-md-12 col-sm-12" >
                        <AsyncSelect
                            isClearable
                            loadOptions={promiseOptions}
                            value={noPaymentOwners} // Set to true to load initial options
                            onInputChange={(newValue) => setInputValue(newValue)}
                            inputValue={inputValue}
                            loadingMessage={() => translate("web.loading", "Loading")}
                            onChange={(selectedItem) => {
                                setNoPaymentOwners(selectedItem)
                            }}
                            isMulti
                            placeholder={translate("web.typeToSearch", "Type To Search") + "..."}
                            noOptionsMessage={() => translate("web.noOptions", "No Options")}
                        />
                    </div>
                    <h3 className='text-danger mt-3'>{translate('web.notes', 'Notes')}</h3>
                    <div className='col-lg-12 col-md-12 col-sm-12'>

                        <NetaqInput
                            onChange={handleInputChange}
                            type={'textareaorganizer'}
                            defaultValue={oldEditValues?.notes}
                            label={translate('web.notes', 'Notes')}
                            name={'notes'}
                            required={false}
                            hideLable={true}
                        />
                    </div>

                    <h3 className='text-danger mt-3'>{translate('web.termsAndConditions', 'Terms And Conditions')}</h3>
                    <div className='col-lg-12 col-md-12 col-sm-12 mt-3  row '
                        style={{ borderRadius: 5, border: '1px solid red', padding: 10, margin: 0 }} >
                        <h3 className='text-danger '>{translate('web.doYouWantToUseEAHSScoringSystem', 'Do You Want To Use EAHS Scoring System')}</h3>
                        <div className='col-lg-3 col-md-3 col-sm-6' >
                            <NetaqInput
                                type={'radio'}
                                name={'termsone'}
                                label={translate('web.yes', 'Yes') + "-"}
                                onChange={() => setFirstTerm(true)}
                            />
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-6' >
                            <NetaqInput
                                type={'radio'}
                                name={'termsone'}
                                label={translate('web.no', 'No') + "-"}
                                onChange={() => setFirstTerm(false)}
                            />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12' >
                            &nbsp;
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12 mt-3' >
                            <NetaqInput
                                type={'checkbox'}
                                urlLabel={translate('web.ECAHOJSONFormat', 'ECAHO JSON Format')}
                                isWithURL={true}
                                url={'https://www.ecaho.org/uploads/68b99fdc3d4d7ed7b5685d6f93969b30_ecahodataformat.pdf'}
                                name={'termsTow'}
                                label={translate('web.otherScoringSystemUsedTerms', "Other Scoring System")}
                                onChange={(e) => { setsecondTerm(e.currentTarget.checked) }}
                            />
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12' >
                            <NetaqInput
                                type={'checkbox'}
                                name={'termsThree'}
                                label={translate('web.termThreeConditions', "Term Three Conditions")}
                                onChange={(e) => { setthirdTerm(e.currentTarget.checked) }}
                            />
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12' >
                            <NetaqInput
                                type={'checkbox'}
                                name={'termsFour'}
                                label={translate('web.termFourConditions', "Term Four Conditions")}
                                onChange={(e) => { setfourthTerm(e.currentTarget.checked) }}
                            />
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12 text-center mt-4'>
                        <ActionButton
                            type={'reset'}
                            text={translate('web.reset', 'Reset')}
                        />
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 text-center mt-4'>
                        <ActionButton
                            type={'btn'}
                            onClick={(e) => handleSubmit(e, true)}
                            text={translate('web.saveAsDraft', 'Save As Draft')}
                        />
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 text-center mt-4'>
                        <ActionButton
                            type={'submit'}
                            text={translate('web.submit', 'Submit')}
                            disabled={secondTerm == false || thirdTerm == false || fourthTerm == false}
                        />
                    </div>


                </form>
            </div>
        </>
    )
}

import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
// import { ToastContainer, toast } from "react-toastify";
import {
  FetchOwnerHorsesDropDownthunk,
} from "../Redux/OwnerProfile/OwnerProfileAction";
import {
} from "../Redux/StudBook/StudBookAction";
import {
  FetchApproveRejectRequestthunk,
  FetchCancelRequestthunk,
  FetchCreateOwnerShipTransferServicesRequeststhunk,
  FetchGetDocumentTypesByServiceIdthunk,
  FetchGetFilebase64thunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
  FetchUpdateOwnerShipTransferServicesRequeststhunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import {
  resetGetServicesRequestByIdData,
  resetToasterMessageServicesRequests,
} from "../Redux/ServicesRequests/ServicesRequestsSlice";
import AsyncSelect from "react-select/async";
import axiosInstance from "../axios/axios";
import { formatDate } from "../helper/formatDate";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import Popup from "reactjs-popup";
import { base64ToBlob } from "../helper/base64ToBlob";
import SimpleFileUpload from "../components/SimpleFileUpload";
import { isMoreThanOneYearBeforeToday } from "../helper/isMoreThanOneYearBeforeToday";
import dayjs from "dayjs";
import { toast, ToastContainer } from "react-toastify";
import { isLessThanUndefinedYearOld } from "../helper/isLessThanUndefinedYearOld";

export default function RequestHorseOwnershipTransferSeller() {
  //env
  const UMBRACOURL = process.env.REACT_APP_UMBRACO;
  const BaseURL = process.env.REACT_APP_BASE_URL;

  //selector
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { ownerHorsesDropDownData } = useSelector(
    (state) => state.ownerProfileSlice
  );
  const {
    toasterMessageServicesRequests,
    servicesRequestsLoading,
    getServicesRequestByIdData,
    getServiceDefinitionByIdData,
    getFilebase64Data,
    getDocumentTypesByServiceIdData,
  } = useSelector((state) => state.servicesRequestsSlice);

  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
  const subOwnerProfilesByIdData = userProfile?.subAccounts ?? [];

  //dispatch
  const dispatch = useDispatch();

  //translate
  const { translate } = useTranslate();

  //navigate
  const navigate = useNavigate();

  //Location
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const serviceId = searchParams.has("serviceId")
    ? searchParams.get("serviceId")
    : null;

  const id = searchParams.has("id") ? searchParams.get("id") : null;

  const action = searchParams.has("action") ? searchParams.get("action") : null;

  //state
  const [formModel, setFormModel] = useState({
    id: "",
    horse: "",
    horseDrop: {
      label: translate("web.horse", "Horse"),
      value: "",
    },
    regNo: "",
    horseName: "",
    gender: "",
    color: "",
    birthDate: "",
    sire: "",
    dam: "",
    origin: "",
    breeder: "",
    owner: "",
    ownerDrop: {
      label: translate("web.owner", "owner"),
      value: "",
    },
    ownerName: "",
    ownerRegNo: "",
    country: "",
    city: "",
    area: "",
    phone: "",
    email: "",
    idNumber: "",
    employeeId: "",
    buyerComment: "",
    eventName: "",
    attachmentFile: null,
    comment: "",
    referenceNumber: "",
    address: "",
    mobile: "",
    filePath: "",
    fileType: "",
    fileName: "",
    documentType: "",
    documentTypeDrop: {
      label: translate("web.type", "Type"),
      value: "",
    },
    isRegistration: true,
    requestsType: "",
    effectiveDate: null,
    isCollectSemen: "No",
    numberOfDoses: 0
  });

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupCancel, setOpenPopupCancel] = useState(false);
  const [ownerListLoading, setOwnerListLoading] = useState(false);
  const _status = useRef("");
  const _isOnlinePayment = useRef("");
  const checkAnotherOwner = useRef(true);
  const [inputValue, setInputValue] = useState("");
  const [timeoutId, setTimeoutId] = useState(0);
  const [uploadAttachmentFile, setUploadAttachmentFile] = useState([]);
  const [checkedItems, setCheckedItems] = useState({
    option1: false,
    option2: false,
    option3: false,
  });
  const [profile, setProfile] = useState({
    label:
      !!userProfile &&
      userProfile?.ownerRegisterNo +
      " - " +
      userProfile[`fullName${currentLanguage == "en" ? "" : "Ar"}`],
    value: localStorage.getItem("EAHSUserID"),
  });
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };
  const subAccountId = localStorage.getItem("currentSubAccountId");

  //function
  const handleFormChange = (e, input) => {
    if (input === "horse") {
      let horse = ownerHorsesDropDownData.filter((item) => item.id == e.value);

      setFormModel({
        ...formModel,
        horseDrop: e,
        horse: e.value,
        regNo: horse[0].registerNumber,
        horseName: horse[0].name,
        gender: horse[0].gender,
        color: horse[0].color,
        birthDate: horse[0].birthDate,
        sire: horse[0].sire,
        dam: horse[0].dam,
        origin: horse[0].origin,
        breeder: horse[0].breeder,
      });
    } else if (input === "owner") {
      if (e?.value != null && e?.value != undefined) {
        setFormModel({
          ...formModel,
          ownerDrop: e,
          owner: e.value,
          email: e.details.email,
          phone: e.details.phone,
          idNumber: e.details.idNumber,
        });
      }
    } else if (input === "documentType") {
      setFormModel({
        ...formModel,
        documentTypeDrop: e,
        documentType: e.value,
      });
    } else if (input === "receiveType") {
      setFormModel({
        ...formModel,
        isRegistration: e.target.value === "Registration" ? true : false,
      });
    } else if (!!input && input === "effectiveDate") {
      setFormModel({
        ...formModel,
        effectiveDate: e.target.value,
        // effectiveDate: e,
      });
    } else if (!!input && input === "isCollectSemen") {
      setFormModel({
        ...formModel,
        isCollectSemen: e.target.value,
        numberOfDoses: e.target.value === "No" ? 0 : formModel.numberOfDoses
      });
    } else {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.value,
      });
    }
  };

  const dismissAll = () => toast.dismiss();

  const handelFormSubmit = (status) => {
    if (
      (action == null || action === "edit" || action === "approve") &&
      formModel.attachmentFile != null &&
      (formModel.documentType === "" || formModel.documentType === null)
    ) {
      toast.error(
        translate("web.pleaseSelectDocumentType", "Please Select Document Type")
      );

      return false;
    }

    if (
      formModel.eventName === "SentBack" &&
      !formModel.isRegistration &&
      (formModel.address === "" ||
        formModel.address == null ||
        formModel.mobile === "" ||
        formModel.mobile == null)
    ) {
      toast.error(
        translate(
          "web.pleaseenteryouraddressandmobilenumber",
          "Please enter your address and mobile number"
        )
      );

      return false;
    }

    if (formModel.effectiveDate != null && isMoreThanOneYearBeforeToday(formModel.effectiveDate)) {
      toast.error(
        translate(
          "web.youCantChooseOwnershipTransferDateMoreThanOneYearBeforeToday",
          "You Can't Choose Ownership Transfer Date More Than One Year Before Today"
        )
      );

      return false;
    }

    if (!!formModel.gender && formModel.gender.toLowerCase() === "STALLION".toLowerCase() && !!formModel.birthDate && !isLessThanUndefinedYearOld(new Date(formModel.birthDate), 2) && formModel.isCollectSemen === "Yes" && (formModel.numberOfDoses === "" || Number(formModel.numberOfDoses) < 1)) {
      toast.error(
        translate(
          "web.pleaseAddNumberOfDoses",
          "Please Add Number Of Doses"
        )
      );

      return false;
    }

    if (
      formModel.horse !== "" &&
      formModel.owner !== "" &&
      formModel.effectiveDate != null &&
      ((Object.values(checkedItems).every((value) => value === true) &&
        formModel.eventName === "") ||
        formModel.eventName !== "")
    ) {
      let params = {
        id: id,
        serviceDefinitionId: serviceId,
        // employeeId:
        //   formModel.eventName == "SentBack"
        //     ? formModel.employeeId
        //     : localStorage.getItem("EAHSUserID"),
        employeeId: localStorage.getItem("EAHSUserID"),
        date: new Date().toISOString(),
        status: formModel.eventName === "SentBack" ? "PendingReview" : status,
        //status: status == "BuyerApproved" ? "WatingBuyerApproval" : status,
        comment: formModel.buyerComment,
        requestDetails: {
          horseId: formModel.horse,
          // ownerId:
          //   formModel.eventName == "SentBack"
          //     ? formModel.employeeId
          //     : localStorage.getItem("EAHSUserID"),
          ownerId:
            formModel.eventName === "SentBack"
              ? formModel.employeeId
              : getUserID(),
          newOwnerId: formModel.owner,
          attachmentFile: formModel.attachmentFile,
          documentTypeId: formModel.documentType,
          effectiveDate: dayjs(formModel.effectiveDate).format("DD/MM/YYYY"),
          isCollectSemen: formModel.isCollectSemen === "Yes",
          numberOfDoses: formModel.numberOfDoses,
          address: formModel.address,
          mobile: formModel.mobile,
          receiveType: formModel.isRegistration ? 1 : 2,
          createdBy: localStorage.getItem("EAHSUserID"),
        },
      };

      if (action == null) {
        //statusRequest.current = "new";
        dispatch(FetchCreateOwnerShipTransferServicesRequeststhunk(params));
      } else if (action === "edit" || formModel.eventName === "SentBack") {
        //statusRequest.current = "edit";
        dispatch(FetchUpdateOwnerShipTransferServicesRequeststhunk(params));
      }
    } else {
      if (
        !Object.values(checkedItems).every((value) => value === true) &&
        formModel.eventName === ""
      ) {
        toast.error(
          translate("web.pleaseCheckAllTerms", "Please Check All Terms")
        );

        return false;
      }
      toast.error(
        translate("web.completeMissingData", "Complete The Missing Data")
      );
    }
  };

  const handelApproveRejectRequest = (status, isOnlinePayment) => {
    _status.current = status;
    _isOnlinePayment.current = isOnlinePayment;

    if (
      !formModel.isRegistration &&
      (formModel.address == "" ||
        formModel.address == null ||
        formModel.mobile == "" ||
        formModel.mobile == null)
    ) {
      toast.error(
        translate(
          "web.pleaseenteryouraddressandmobilenumber",
          "Please enter your address and mobile number"
        )
      );

      return false;
    }

    let params = {
      id: id,
      // employeeId: formModel.employeeId,
      employeeId: getUserID(),
      status: status,
      comment: formModel.buyerComment,
      address: formModel.address,
      mobile: formModel.mobile,
      receiveType: formModel.isRegistration ? 1 : 2,
    };

    dispatch(FetchApproveRejectRequestthunk(params));
  };

  const handleUploadServiceRequestDocumentChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          attachmentFile: e.target.files[0],
        });

        setUploadAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const handelSelectedAccountChange = async (element) => {
    let params = {
      ownerId: element.value,
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    setProfile(element);

    setFormModel({
      ...formModel,
      horse: "",
      horseDrop: {
        label: translate("web.horse", "Horse"),
        value: "",
      },
      gender: "",
      color: "",
      birthDate: "",
      sire: "",
      dam: "",
      origin: "",
      breeder: "",
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
  };

  const closeModal = () => setOpenPopup(false);
  const closeModalCancel = () => setOpenPopupCancel(false);

  function CheckIfAgentHasPermission() {
    const AgentHasPermission = userProfile.subAccounts
      .filter((x) => x.value == subAccountId)[0]
      .ownersPermissionsList.some(
        (x) =>
          x.permissionName == "OwnerShipTransfer" || x.permissionName == "All"
      );
    return AgentHasPermission;
  }

  const getUserID = () => {
    const userID =
      !!userProfile && userProfile?.accountType != "Agent" ? profile.value : subAccountId;
    return userID;
  };

  const getSelectedAccountName = () => {
    const selectAccount = userProfile.subAccounts.filter(
      (x) => x.value == subAccountId
    );
    return selectAccount[0];
  };

  const handelCancelRequest = () => {
    let params = {
      serviceId: id,
      ownerId: localStorage.getItem("EAHSUserID"),
    };

    dispatch(FetchCancelRequestthunk(params));
  };

  // asyunc search
  const promiseOptions = (searchKeyword) =>
    new Promise((resolve) => {
      if (timeoutId > 0) {
        clearTimeout(timeoutId);
      }

      const id = setTimeout(async () => {
        const response = await axiosInstance.post(
          "/StudBook/FilterOwnersOrUsers",
          {
            langauge: localStorage.getItem("eahsLang") ?? "en",
            ownerId: searchKeyword,
            skip: 0,
            take: 10000,
            tableType: "user",
          }
        );

        const data = await response.data.ownersList;

        const options = data.map((item) => ({
          label: item.ownerId + " - " + item.name,
          value: item.id,
          details: item,
        }));
        resolve(options);
      }, 1000);

      setTimeoutId(id);
    });

  //Effect

  useEffect(() => {
    if (!!userProfile && userProfile?.haveProfileSignture != true) {
      toast.error(
        translate(
          "web.completeOwnerProfileData",
          "Please complete the personal data of the profile."
        )
      );

      navigate("/profile");
    }

    if (!!userProfile && userProfile?.accountType == "Agent") {
      if (userProfile.id == subAccountId) {
        navigate("/404-not-found");
      }

      if (!CheckIfAgentHasPermission()) {
        navigate("/404-not-found");
      }
    }

    let params = {
      ownerId: getUserID(),
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    let subOwnerProfilesParams = {
      id: getUserID(),
      langauge: currentLanguage,
    };
    dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));

    dispatch(FetchGetServiceDefinitionByIdthunk(serviceId));

    let documentTypesParams = {
      id: serviceId,
      language: currentLanguage,
    };
    dispatch(FetchGetDocumentTypesByServiceIdthunk(documentTypesParams));

    //dispatch(FetchGetOwnersListthunk(currentLanguage));

    return () => {
      // You Should Reset the getServicesRequestByIdData to the init status
    }

  }, [currentLanguage]);

  useEffect(() => {
    if (
      Object.keys(getServicesRequestByIdData).length > 0 &&
      checkAnotherOwner.current && (getServicesRequestByIdData.serviceDefinitionId == serviceId)
    ) {
      if (ownerHorsesDropDownData.length > 0) {
        setFormModel({
          ...formModel,
          horse: getServicesRequestByIdData.ownerShipTransfer.horseId,
          horseDrop: {
            label:
              getServicesRequestByIdData.ownerShipTransfer.horse
                .registrationNumber +
              " - " +
              getServicesRequestByIdData.ownerShipTransfer.horse.nameEn,
            value: getServicesRequestByIdData.ownerShipTransfer.horseId,
          },
          regNo:
            getServicesRequestByIdData.ownerShipTransfer.horse
              .registrationNumber,
          horseName: getServicesRequestByIdData.ownerShipTransfer.horse.nameEn,
          gender: getServicesRequestByIdData.ownerShipTransfer.horse.gender,
          color:
            currentLanguage == "en"
              ? getServicesRequestByIdData.ownerShipTransfer.horse.horseColor
                .nameEn
              : getServicesRequestByIdData.ownerShipTransfer.horse.horseColor
                .nameAr,
          birthDate:
            getServicesRequestByIdData.ownerShipTransfer.horse.birthDate,
          sire: getServicesRequestByIdData.ownerShipTransfer.horse.sireNameEn,
          dam: getServicesRequestByIdData.ownerShipTransfer.horse.damNameEn,

          employeeId:
            getServicesRequestByIdData.requestsType == "Portal"
              ? getServicesRequestByIdData.ownerShipTransfer.createdBy
              : getServicesRequestByIdData.ownerShipTransfer.ownerId,
          owner: getServicesRequestByIdData.ownerShipTransfer.newOwnerId,
          ownerDrop: {
            label:
              getServicesRequestByIdData.ownerId +
              " - " +
              getServicesRequestByIdData.ownerName,
            value: getServicesRequestByIdData.ownerShipTransfer.newOwnerId,
          },
          phone: getServicesRequestByIdData.ownerPhone,
          email: getServicesRequestByIdData.ownerEmail,
          idNumber: getServicesRequestByIdData.idNumber,
          eventName: getServicesRequestByIdData.eventName,
          comment: getServicesRequestByIdData.comment,
          requestsType: getServicesRequestByIdData.requestsType,
          referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,
          buyerComment: getServicesRequestByIdData.ownerShipTransfer.buyerComment != null ? getServicesRequestByIdData.ownerShipTransfer.buyerComment : "",
          effectiveDate: getServicesRequestByIdData.ownerShipTransfer.effectiveDate != null ? dayjs(getServicesRequestByIdData.ownerShipTransfer.effectiveDate).format("YYYY-MM-DD") : null,
          isCollectSemen: getServicesRequestByIdData.ownerShipTransfer.isCollectSemen == true ? "Yes" : "No",
          numberOfDoses: getServicesRequestByIdData.ownerShipTransfer.numberOfDoses,
          address: getServicesRequestByIdData.ownerShipTransfer.address,
          mobile: getServicesRequestByIdData.ownerShipTransfer.mobile,
          isRegistration: getServicesRequestByIdData.ownerShipTransfer.isRegistration,
          filePath: getServicesRequestByIdData.ownerShipTransfer.filePath,
          fileType: getServicesRequestByIdData.ownerShipTransfer.fileType,
          fileName: getServicesRequestByIdData.ownerShipTransfer.fileName,
          breeder: getServicesRequestByIdData.ownerShipTransfer.horse.breeder,
          origin: getServicesRequestByIdData.ownerShipTransfer.horse.exportCountry,
          documentType: getServicesRequestByIdData.ownerShipTransfer.documentTypeId ?? "",
          documentTypeDrop: {
            label: getServicesRequestByIdData.ownerShipTransfer.documentTypeName,
            value: getServicesRequestByIdData.ownerShipTransfer.documentTypeId ?? "",
          },
        });
      } else {
        setFormModel({
          ...formModel,
          horse: getServicesRequestByIdData.ownerShipTransfer.horseId,
          horseDrop: {
            label:
              getServicesRequestByIdData.ownerShipTransfer.horse
                .registrationNumber +
              " - " +
              getServicesRequestByIdData.ownerShipTransfer.horse.nameEn,
            value: getServicesRequestByIdData.ownerShipTransfer.horseId,
          },
          regNo:
            getServicesRequestByIdData.ownerShipTransfer.horse
              .registrationNumber,
          horseName: getServicesRequestByIdData.ownerShipTransfer.horse.nameEn,
          gender: getServicesRequestByIdData.ownerShipTransfer.horse.gender,
          color:
            currentLanguage === "en"
              ? getServicesRequestByIdData.ownerShipTransfer.horse.horseColor
                .nameEn
              : getServicesRequestByIdData.ownerShipTransfer.horse.horseColor
                .nameAr,
          birthDate:
            getServicesRequestByIdData.ownerShipTransfer.horse.birthDate,
          sire: getServicesRequestByIdData.ownerShipTransfer.horse.sireNameEn,
          dam: getServicesRequestByIdData.ownerShipTransfer.horse.damNameEn,

          employeeId:
            getServicesRequestByIdData.requestsType === "Portal"
              ? getServicesRequestByIdData.ownerShipTransfer.createdBy
              : getServicesRequestByIdData.ownerShipTransfer.ownerId,
          owner: getServicesRequestByIdData.ownerShipTransfer.newOwnerId,
          ownerDrop: {
            label:
              getServicesRequestByIdData.ownerId +
              " - " +
              getServicesRequestByIdData.ownerName,
            value: getServicesRequestByIdData.ownerShipTransfer.newOwnerId,
          },
          phone: getServicesRequestByIdData.ownerPhone,
          email: getServicesRequestByIdData.ownerEmail,
          idNumber: getServicesRequestByIdData.idNumber,
          eventName: getServicesRequestByIdData.eventName,
          requestsType: getServicesRequestByIdData.requestsType,
          comment: getServicesRequestByIdData.comment,
          referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,
          buyerComment: getServicesRequestByIdData.ownerShipTransfer.buyerComment != null ? getServicesRequestByIdData.ownerShipTransfer.buyerComment : "",
          effectiveDate: getServicesRequestByIdData.ownerShipTransfer.effectiveDate != null ? dayjs(getServicesRequestByIdData.ownerShipTransfer.effectiveDate).format("YYYY-MM-DD") : null,
          isCollectSemen: getServicesRequestByIdData.ownerShipTransfer.isCollectSemen == true ? "Yes" : "No",
          numberOfDoses: getServicesRequestByIdData.ownerShipTransfer.numberOfDoses,
          address: getServicesRequestByIdData.ownerShipTransfer.address,
          mobile: getServicesRequestByIdData.ownerShipTransfer.mobile,
          isRegistration: getServicesRequestByIdData.ownerShipTransfer.isRegistration,
          filePath: getServicesRequestByIdData.ownerShipTransfer.filePath,
          fileType: getServicesRequestByIdData.ownerShipTransfer.fileType,
          fileName: getServicesRequestByIdData.ownerShipTransfer.fileName,
          breeder: getServicesRequestByIdData.ownerShipTransfer.horse.breeder,
          origin: getServicesRequestByIdData.ownerShipTransfer.horse.exportCountry,
          documentType: getServicesRequestByIdData.ownerShipTransfer.documentTypeId ?? "",
          documentTypeDrop: {
            label: getServicesRequestByIdData.ownerShipTransfer.documentTypeName,
            value: getServicesRequestByIdData.ownerShipTransfer.documentTypeId ?? "",
          },
        });
      }

      if (
        getServicesRequestByIdData.ownerShipTransfer.filePath != null &&
        getServicesRequestByIdData.ownerShipTransfer.filePath !== ""
      ) {
        dispatch(
          FetchGetFilebase64thunk(
            getServicesRequestByIdData.ownerShipTransfer.filePath
          )
        );
        // setUploadAttachmentFile({
        //   file: getServicesRequestByIdData.ownerShipTransfer.attachmentUrl,
        //   name: getServicesRequestByIdData.ownerShipTransfer.attachmentUrl.substr(
        //     getServicesRequestByIdData.ownerShipTransfer.attachmentUrl.lastIndexOf(
        //       "/"
        //     ) + 1
        //   ),
        // });
      }

      if (
        getServicesRequestByIdData.ownerShipTransfer.ownerId !==
        getServicesRequestByIdData.ownerShipTransfer.createdBy &&
        subOwnerProfilesByIdData.length > 0
      ) {
        let params = {
          ownerId: getServicesRequestByIdData.ownerShipTransfer.ownerId,
          skip: 0,
          take: 1000,
          langauge: currentLanguage,
          horseTypeFilter: "1",
          searchValue: "",
          sortBy: "-1",
        };
        dispatch(FetchOwnerHorsesDropDownthunk(params));

        //update checkAnotherOwner
        checkAnotherOwner.current = false;
        //update profile
        setProfile({
          label:
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.ownerShipTransfer.ownerId
            ).registerNo +
            " - " +
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.ownerShipTransfer.ownerId
            )[`text${currentLanguage == "en" ? "" : "Ar"}`],
          value: subOwnerProfilesByIdData.filter(
            (item) =>
              item.value == getServicesRequestByIdData.ownerShipTransfer.ownerId
          ).value,
        });
      }

      checkAnotherOwner.current = false;

      setCheckedItems({
        option1: true,
        option2: true,
        option3: true,
      });
    }
  }, [
    ownerHorsesDropDownData,
    getServicesRequestByIdData,
    subOwnerProfilesByIdData,
    getDocumentTypesByServiceIdData,

  ]);

  useEffect(() => {
    if (id != null) {
      let params = {
        language: currentLanguage,
        id: id,
      };

      checkAnotherOwner.current = true;
      dispatch(FetchGetServicesRequestByIdthunk(params));
    }

    return () => {
      dispatch(resetGetServicesRequestByIdData());
    };
  }, [id]);

  useEffect(() => {
    //save
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      if (_status.current === "BuyerApproved") {
        toast.success(
          translate(
            "web.yourRequestHasSuccessfully",
            "Your Request Has Successfully"
          )
        );
      } else {
        toast.success(
          translate("web.dataSavedSuccessfully", "Data Saved Successfully")
        );
      }

      dispatch(resetToasterMessageServicesRequests());

      setTimeout(() => {
        if (_status.current === "BuyerApproved") {
          //go to payment
          //const returnUrl = `${BaseURL}/servicesrequestspaymentsuccessful?serviceRequestId=${id}`;
          const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id}&paymentType=7&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}&isOnline=${_isOnlinePayment.current}`;
        } else {
          navigate(`/profile?activeTab=${"e_services"}`);
        }
      }, 1000);
    }

    //error
    else if (toasterMessageServicesRequests != null && toasterMessageServicesRequests !== "save") {
      toast.error(
        toasterMessageServicesRequests
      );

      dispatch(resetToasterMessageServicesRequests());
    }

  }, [toasterMessageServicesRequests]);

  console.log("from", formModel)
  return (
    <div className="container-fluid container-fluid-padding">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${servicesRequestsLoading || ownerListLoading
          ? "classdisplay"
          : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>
      {
        //toast
      }
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />
      <div className="row rtl">
        <div className="col-md-12 col-sm-12 row rtl ">
          {
            // title
          }
          <div className="col-md-12 col-sm-12 mb-4">
            <h1 className="fontWeight display-inline">
              {currentLanguage == "en"
                ? getServiceDefinitionByIdData.nameEn
                : getServiceDefinitionByIdData.nameAr}
            </h1>
            <h3 className="fontWeight display-inline">
              {formModel.referenceNumber}{" "}
            </h3>
          </div>
          {
            // Selected Account
          }
          {subOwnerProfilesByIdData.length > 0 &&
            (action == null || action == "edit") && (
              <div className="col-md-6 col-sm-12 mb-5">
                {!!userProfile && userProfile?.accountType != "Agent" ? (
                  <React.Fragment>
                    <label className="capatalize_text">
                      {translate("web.selectedAccount", "Selected Account")}
                    </label>
                    <Select
                      className="mt-10 zindex200"
                      options={[
                        {
                          label:
                            userProfile?.ownerRegisterNo +
                            " - " +
                            userProfile[
                            `fullName${currentLanguage == "en" ? "" : "Ar"}`
                            ],
                          value: localStorage.getItem("EAHSUserID"),
                        },
                        ...(!!subOwnerProfilesByIdData &&
                          !!subOwnerProfilesByIdData.length > 0 &&
                          subOwnerProfilesByIdData.map((a) => ({
                            label:
                              a.registerNo +
                              " - " +
                              a[`text${currentLanguage == "en" ? "" : "Ar"}`],
                            value: a.value,
                          }))),
                      ]}
                      value={profile}
                      onChange={(e) => {
                        handelSelectedAccountChange(e);
                      }}
                      placeholder={localStorage.getItem("EAHSUserFullName")}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      styles={customStyles}
                    />
                  </React.Fragment>
                ) : (
                  <label className="capatalize_text">
                    {getSelectedAccountName().registerNo +
                      " - " +
                      getSelectedAccountName()[
                      `text${currentLanguage == "en" ? "" : "Ar"}`
                      ]}
                  </label>
                )}
              </div>
            )}
          {
            // horse
          }
          <h2 className="fontWeight colorred">
            {translate("web.horse", "Horse")}
          </h2>
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard row rtl  mb-25">
            <div className="col-md-6 col-sm-12 ">
              <label>
                {translate("web.horse", "Horse")}
                <i className="colorred">*</i>
              </label>
              <Select
                options={[
                  {
                    label: translate("web.horse", "Horse"),
                    value: "",
                  },
                  ...(!!ownerHorsesDropDownData &&
                    ownerHorsesDropDownData.map((a) => ({
                      label: a.registerNumber + " - " + a.name,
                      value: a.id,
                    }))),
                ]}
                value={formModel.horseDrop}
                onChange={(e) => handleFormChange(e, "horse")}
                placeholder={translate("web.horse", "Horse")}
                name={"horse"}
                isSearchable
                noOptionsMessage={() =>
                  translate("web.noOptions", "No Options")
                }
                autoFocus={true}
                isDisabled={
                  action == "view" ||
                  // (action == "approve" && formModel.eventName != "SentBack")
                  action == "approve"
                }
              />
            </div>

            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.origin", "Origin")}
                name={"origin"}
                type={"text"}
                disabled={true}
                value={formModel.origin}
              />
            </div>
            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.breeder", "Breeder")}
                name={"breeder"}
                type={"text"}
                disabled={true}
                value={formModel.breeder}
              />
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.gender", "Gender")}
                name={"gender"}
                type={"text"}
                disabled={true}
                value={translate(
                  `web._${formModel.gender.toLowerCase()}`,
                  formModel.gender
                )}
              />
            </div>
            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.color", "Color")}
                name={"color"}
                type={"text"}
                disabled={true}
                value={formModel.color}
              />
            </div>
            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.birthDate", "Birth Date")}
                name={"birthDate"}
                type={"text"}
                disabled={true}
                value={
                  formModel.birthDate != null && formModel.birthDate !== ""
                    ? formModel.birthDate && formatDate(formModel.birthDate)
                    : ""
                }
              />
            </div>
            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.sire", "Sire")}
                name={"sire"}
                type={"text"}
                disabled={true}
                value={formModel.sire}
              />
            </div>
            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.dam", "Dam")}
                name={"dam"}
                type={"text"}
                disabled={true}
                value={formModel.dam}
              />
            </div>
          </div>
          {
            // buyer owner
          }
          <h2 className="fontWeight colorred">
            {translate("web.buyer", "Buyer")}
          </h2>
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard  row rtl mb-25">
            <div className="col-md-6 col-sm-12">
              <label>
                {translate("web.owner", "Owner")}
                <i className="colorred">*</i>
              </label>
              <AsyncSelect
                isClearable
                loadOptions={promiseOptions}
                defaultOptions={false} // Set to true to load initial options
                onInputChange={(newValue) => setInputValue(newValue)}
                inputValue={inputValue}
                value={formModel.ownerDrop}
                loadingMessage={() => translate("web.loading", "Loading")}
                onChange={(e) => handleFormChange(e, "owner")}
                placeholder={
                  translate("web.typeToSearch", "Type To Search") + "..."
                }
                noOptionsMessage={() =>
                  translate("web.noOptions", "No Options")
                }
                isDisabled={action === "view" || action === "approve"}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <NetaqInput
                label={translate("web.smsPhoneNo", "Mobile No")}
                name={"mobileNo"}
                type={"text"}
                disabled={true}
                value={formModel.phone}
              />
            </div>
            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate(
                  "web.passportOrEmiratesId",
                  "Passport - EmiratesId"
                )}
                name={"name"}
                type={"text"}
                disabled={true}
                value={formModel.idNumber}
                isDash={true}
              />
            </div>
            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.email", "Email")}
                name={"email"}
                type={"text"}
                disabled={true}
                value={formModel.email}
              />
            </div>
          </div>
          {
            //ownership Transfer Data
          }
          <h2 className="fontWeight colorred">
            {translate("web.ownershipTransferData", "Ownership Transfer Data")}
          </h2>
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard  row rtl mb-25">
            <div className="col-md-4 col-sm-12 rtl service">
              <NetaqInput
                htmlTags={
                  formModel.effectiveDate ?
                    <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                      {
                        dayjs(formModel.effectiveDate).format("DD/MM/YYYY")
                      }
                    </p> :
                    <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                      DD/MM/YYYY
                    </p>
                }
                name={"effectiveDate"}
                type={"normalDateService"}
                //onKeydown={(e) => e.preventDefault()}
                label={translate("web.ownershipTransferDate", "Ownership Transfer Date")}
                onChange={(e) => handleFormChange(e, "effectiveDate")}
                value={formModel.effectiveDate}
                required={true}
                maxDate={dayjs().format("YYYY-MM-DD")}
                disabled={
                  (action === "approve" || action === "view") ?
                    true
                    : false
                }
              />
              {/* <NetaqInput
                name={"effectiveDate"}
                type={"date"}
                label={translate("web.ownershipTransferDate", "Ownership Transfer Date")}
                onChange={(e) => handleFormChange(e, "effectiveDate")}
                value={formModel.effectiveDate}
                required={true}
                maxDate={dayjs()}
                disabled={
                  (action === "approve" || action === "view") ?
                    true
                    : false
                }
              /> */}
            </div>
            {
              //collect semen section
            }
            {
              !!formModel.gender && formModel.gender.toLowerCase() === "STALLION".toLowerCase() && !!formModel.birthDate && !isLessThanUndefinedYearOld(new Date(formModel.birthDate), 2) &&
              <div className="col-md-6 col-sm-12 rtl ">
                <label className="capatalize_text">
                  {translate("web.doYouCollectSemenFromThisStallion", "Do You Collect Semen From This Stallion ?")}
                </label>
                <div className="AuctionFilterradioServiceRequestContainer mt-3 row">
                  <div style={{ maxWidth: 150 }}>
                    <NetaqInput
                      name={"isCollectSemen"}
                      type={"radioServiceRequest"}
                      value={"Yes"}
                      label={`${translate("web.yes", "YES")}`}
                      onChange={(e) => handleFormChange(e, "isCollectSemen")}
                      checked={
                        formModel.isCollectSemen === "Yes"
                      }
                      disabled={
                        (action === "approve" || action === "view") ?
                          true
                          : false
                      }
                    />
                  </div>
                  <div style={{ maxWidth: 150 }}>
                    <NetaqInput
                      name={"isCollectSemen"}
                      type={"radioServiceRequest"}
                      value={"No"}
                      label={`${translate("web.no", "NO")}`}
                      onChange={(e) => handleFormChange(e, "isCollectSemen")}
                      checked={
                        formModel.isCollectSemen === "No"
                      }
                      disabled={
                        (action === "approve" || action === "view") ?
                          true
                          : false
                      }
                    />
                  </div>
                  {
                    formModel.isCollectSemen === "Yes" &&
                    <div className="" style={{ maxWidth: 300 }}>
                      <NetaqInput
                        name={"numberOfDoses"}
                        key={"numberOfDoses"}
                        type={"number"}
                        ishorizon={true}
                        value={formModel.numberOfDoses}
                        minValue={1}
                        label={translate("web.numberOfDoses", "Number Of Doses")}
                        onChange={handleFormChange}
                        disabled={
                          (action === "approve" || action === "view") ?
                            true
                            : false
                        }
                      />
                    </div>
                  }

                </div>

              </div>

            }
          </div>
          {
            // upload attachment
          }
          <h2 className="fontWeight colorred">
            {translate("web.uploadDocument", "Upload Document")}
          </h2>
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard row rtl  mb-25">
            <div className="col-md-12 col-sm-12 row mb-25 row align-items-center">
              <h3>
                {translate("web.serviceRequestDocument", "Supported Document")}
              </h3>
              {
                //document type dropdown
              }
              <div className="col-md-4 col-sm-12">
                <label>
                  {translate("web.documentType", "Document Type")}
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.type", "Type"),
                      value: "",
                    },
                    ...(!!getDocumentTypesByServiceIdData &&
                      getDocumentTypesByServiceIdData.map((a) => ({
                        label: a.nameEn,
                        value: a.id,
                      }))),
                  ]}
                  value={formModel.documentTypeDrop}
                  onChange={(e) => handleFormChange(e, "documentType")}
                  placeholder={translate("web.type", "Type")}
                  name={"documentType"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  autoFocus={true}
                  isDisabled={
                    action === "view" ||
                      (action === "approve" && formModel.eventName !== "SentBack")
                      ? true
                      : false
                  }
                />
              </div>
              {
                // <div className="col-md-4 col-sm-12 mt-auto align-self-center justify-content-end d-flex mx-auto ">
                //   {
                //     //document file befor save
                //   }
                //   {uploadAttachmentFile != null && (
                //     <Link
                //       to={uploadAttachmentFile.file}
                //       className="C89C66 flex-center cursorPointer "
                //       target="_blank"
                //     >
                //       <i className="fa fa-file fa-3x"></i>
                //       <span className="black-color fontsize18 marginrightleft-10">
                //         {uploadAttachmentFile.name}
                //       </span>
                //     </Link>
                //   )}
                //   {
                //     //document file after save
                //   }
                //   {formModel.filePath != null &&
                //     formModel.filePath != "" &&
                //     !!getFilebase64Data &&
                //     uploadAttachmentFile == null && (
                //       <Link
                //         to={base64ToBlob(
                //           getFilebase64Data,
                //           formModel.fileType === ".pdf"
                //             ? "application/pdf"
                //             : "image/png"
                //         )}
                //         className="C89C66 flex-center cursorPointer mb-25"
                //         alt={formModel.fileName}
                //         target="_blank"
                //       >
                //         <i className="fa fa-file fa-3x"></i>
                //         <span className="black-color fontsize18 marginrightleft-10">
                //           {formModel.fileName}
                //         </span>
                //       </Link>
                //     )}
                // </div>
              }
              {
                //document file
              }
              <div className="col-md-6 col-sm-12 ms-auto">
                {
                  //document file title
                }
                <label className=" Gray_color">
                  {translate(
                    "web.serviceRequestDocumentType",
                    "Supported Document (pdf,png)"
                  )}
                </label>
                {
                  //document file button
                }
                <SimpleFileUpload
                  isMulti={false}
                  initialFiles={
                    formModel.filePath != null &&
                      formModel.filePath != "" &&
                      !!getFilebase64Data &&
                      uploadAttachmentFile.length == 0
                      ? [
                        {
                          file: base64ToBlob(
                            getFilebase64Data,
                            formModel.fileType === ".pdf"
                              ? "application/pdf"
                              : "image/png"
                          ),
                          name: formModel.fileName,
                        },
                      ]
                      : uploadAttachmentFile
                  }
                  isServer={
                    formModel.filePath != null &&
                      formModel.filePath != "" &&
                      !!getFilebase64Data &&
                      uploadAttachmentFile.length == 0
                      ? true
                      : false
                  }
                  onChange={handleUploadServiceRequestDocumentChange}
                  accept={"image/*,application/pdf"}
                  disabled={
                    action === "view"
                      //||(action === "approve" && formModel.eventName !== "SentBack")
                      ? true
                      : false
                  }
                />
                {
                  // <UploadFile
                  //   text={"Upload"}
                  //   className="maxWidth150 mt-10"
                  //   ismultiFile={false}
                  //   disabled={
                  //     action == "view" ||
                  //     (action === "approve" && formModel.eventName != "SentBack")
                  //       ? true
                  //       : false
                  //   }
                  //   onChange={handleUploadServiceRequestDocumentChange}
                  //   iconClassName={"fa-solid fa-upload fa-lg marginrightleft-5"}
                  //   accept={"image/*,application/pdf"}
                  // />
                }
              </div>
            </div>
          </div>
          {
            //Comments
          }
          {((formModel.comment !== "" && formModel.comment != null) ||
            (formModel.buyerComment !== "" && formModel.buyerComment != null) ||
            action === "approve") && (
              <React.Fragment>
                <h2 className="fontWeight colorred">
                  {translate("web.comments", "Comments")}
                </h2>
                <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
                  {
                    // eahs Comment
                  }
                  {formModel.comment !== "" && formModel.comment != null && (
                    <div className="col-md-6 col-sm-12">
                      <NetaqInput
                        label={translate("web.eahsComments", "EAHS Comments")}
                        name={"adminComment"}
                        type={"textarea"}
                        maxchars={1150}
                        value={formModel.comment}
                        disabled={true}
                      />
                    </div>
                  )}
                  {
                    // buyer Comment
                  }
                  {((action === "approve") ||
                    (action === "edit" &&
                      formModel.eventName === "BuyerRejected") ||
                    (action === "view" &&
                      !!formModel.buyerComment)) && (
                      <div className="col-md-6 col-sm-12 ">
                        <NetaqInput
                          label={translate("web.buyerComments", "Buyer Comments")}
                          name={"buyerComment"}
                          type={"textarea"}
                          placeholder={translate(
                            "web.buyerComments",
                            "Buyer Comments"
                          )}
                          maxchars={1150}
                          onChange={handleFormChange}
                          value={formModel.buyerComment}
                          disabled={
                            action === "approve" &&
                              formModel.requestsType === "Portal"
                              ? // (formModel.requestsType == "Portal" ||
                              //   formModel.eventName == "SentBack")
                              false
                              : true
                          }
                        />
                      </div>
                    )}
                </div>
              </React.Fragment>
            )}
          {
            //How to receive a horse passport
          }
          {((formModel.address !== "" && formModel.address != null) ||
            (formModel.mobile !== "" && formModel.mobile != null) ||
            action === "approve") && (
              <React.Fragment>
                <h2 className="fontWeight colorred">
                  {translate(
                    "web.receiveHorsePassport",
                    "How to receive a horse passport"
                  )}
                </h2>
                <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
                  <React.Fragment>
                    <div className="AuctionFilterRadioContainer">
                      <div style={{ margin: "auto 0" }}>
                        <NetaqInput
                          name={"receiveType"}
                          key={"Registration"}
                          type={"radioServiceRequest"}
                          value={"Registration"}
                          label={translate(
                            "web.registrationOffice",
                            "Received From EAHS Office"
                          )}
                          onChange={(e) => handleFormChange(e, "receiveType")}
                          checked={
                            // formModel.isRegistration === true ? true : false
                            formModel.isRegistration
                          }
                          disabled={
                            action === "approve" &&
                              formModel.requestsType === "Portal"
                              ? // (formModel.requestsType == "Portal" ||
                              //   formModel.eventName == "SentBack")
                              false
                              : true
                          }
                        />
                      </div>
                      <div style={{ margin: "auto 30px" }}>
                        <NetaqInput
                          name={"receiveType"}
                          key={"Post"}
                          type={"radioServiceRequest"}
                          value={"Post"}
                          label={translate("web.postOffice", "Post Delivery")}
                          onChange={(e) => handleFormChange(e, "receiveType")}
                          checked={
                            !formModel.isRegistration
                          }
                          disabled={
                            action === "approve" &&
                              formModel.requestsType === "Portal"
                              ? // (formModel.requestsType == "Portal" ||
                              //   formModel.eventName == "SentBack")
                              false
                              : true
                          }
                        />
                      </div>
                    </div>

                    <div
                      className="col-md-6 col-sm-12 mt-3"
                      style={{
                        display:
                          formModel.isRegistration === false ? "block" : "none",
                      }}
                    >
                      <NetaqInput
                        label={translate("web.address", "Address")}
                        name={"address"}
                        type={"text"}
                        placeholder={translate("web.address", "Address")}
                        onChange={handleFormChange}
                        value={formModel.address}
                        disabled={
                          action === "approve" &&
                            formModel.requestsType === "Portal"
                            ? // (formModel.requestsType == "Portal" ||
                            //   formModel.eventName == "SentBack")
                            false
                            : true
                        }
                        required={true}
                      />
                    </div>
                    <div
                      className="col-md-6 col-sm-12 mt-3"
                      style={{
                        display:
                          formModel.isRegistration === false ? "block" : "none",
                      }}
                    >
                      <NetaqInput
                        label={translate("web.smsPhoneNo", "Mobile No")}
                        name={"mobile"}
                        type={"text"}
                        placeholder={translate("web.smsPhoneNo", "Mobile No")}
                        onChange={handleFormChange}
                        value={formModel.mobile}
                        disabled={
                          action === "approve" &&
                            formModel.requestsType === "Portal"
                            ? // (formModel.requestsType == "Portal" ||
                            //   formModel.eventName == "SentBack")
                            false
                            : true
                        }
                        required={true}
                      />
                    </div>
                  </React.Fragment>
                </div>
              </React.Fragment>
            )}
          {
            //terms
          }
          <h2 className="fontWeight colorred">
            {translate("web.terms", "Terms")}
          </h2>

          <div className="col-md-12 col-sm-12 rtl p0">
            <NetaqInput
              type={"checkbox"}
              label={translate(
                "web.ownershipTransferNote2",
                "This application is not valid without original passport"
              )}
              name="option1"
              checked={checkedItems.option1}
              onChange={handleCheckboxChange}
              disabled={formModel.eventName === "" ? false : true}
            />
          </div>
          <div className="col-md-12 col-sm-12 rtl p0">
            <NetaqInput
              type={"checkbox"}
              label={translate(
                "web.ownershipTransferNote3",
                "This application is not valid without original passport"
              )}
              name="option2"
              checked={checkedItems.option2}
              onChange={handleCheckboxChange}
              disabled={formModel.eventName === "" ? false : true}
            // onChange={() => {
            //   setcheckes(
            //     Array.from(
            //       document.querySelectorAll('input[type="checkbox"]')
            //     ).map((checkbox) => checkbox.checked)
            //   );
            // }}
            />
          </div>
          <div className="col-md-12 col-sm-12 rtl p0">
            <NetaqInput
              type={"checkbox"}
              label={translate(
                "web.ServiceGeneralNote",
                "Service General Note"
              )}
              name="option3"
              checked={checkedItems.option3}
              onChange={handleCheckboxChange}
              disabled={formModel.eventName === "" ? false : true}
            />
          </div>
          {
            // buttons
          }
          <div className="col-md-12 col-sm-12 mt-15 text-end">
            {
              //cancel
            }
            {
              (action === "edit" && formModel.eventName !== "Draft") &&
              <ActionButton
                className={"btn-danger requestBtn"}
                type={"requests"}
                label={translate("web.cancel", "Cancel")}
                text={
                  <>
                    <i className="fa-solid fa-circle-xmark fa-lg marginrightleft-10"></i>
                    {translate("web.cancel", "Cancel")}
                  </>
                }
                margin={"auto 10px"}
                width={"250px"}
                onClick={() => {
                  setOpenPopupCancel((o) => !o);
                }}
              //onClick={handelCancelRequest}
              />
            }
            {
              //draft/submit btn
            }
            {action == null ||
              action === "edit" ||
              (action === "approve" && formModel.eventName === "SentBack") ? (
              <React.Fragment>
                <ActionButton
                  type={"requests"}
                  className="btn buttonformshowentrycard height46 linkbtn"
                  label={translate("web.draft", "Draft")}
                  text={
                    <React.Fragment>
                      <i className="fa fa-save fa-lg marginrightleft-10"></i>
                      {translate("web.draft", "Draft")}
                    </React.Fragment>
                  }
                  margin={"auto 10px"}
                  width={"250px"}
                  onClick={() => handelFormSubmit("Draft")}
                  disabled={
                    // formModel.horse != "" &&
                    // formModel.owner != "" &&
                    formModel.eventName === "" ||
                      formModel.eventName === "Draft"
                      ? false
                      : true
                  }
                />
                <ActionButton
                  type={"requests"}
                  className="btn-success text-white requestBtn"
                  label={translate("web.submit", "Submit")}
                  text={
                    <React.Fragment>
                      <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                      {translate("web.submit", "Submit")}
                    </React.Fragment>
                  }
                  margin={"auto 25px"}
                  width={"200"}
                  onClick={() =>
                    handelFormSubmit(
                      action == "edit" && formModel.eventName == "BuyerRejected"
                        ? "WatingBuyerApproval"
                        : "WatingBuyerApproval"
                    )
                  }
                // disabled={
                //   formModel.horse != "" && formModel.owner != ""
                //     ? false
                //     : true
                // }
                />
              </React.Fragment>
            ) : action === "approve" && formModel.eventName !== "SentBack" ? (
              //approve/reject btn
              <React.Fragment>
                <div className="d-flex flex-wrap justify-content-md-end justify-content-center">
                  <div className="d-flex my-2 mx-1 flex-column justify-content-center nowidthClass">
                    <ActionButton
                      type={"submit"}

                      label={translate("web.reject", "Reject")}
                      text={
                        <React.Fragment>
                          <i className="fa-solid fa-ban fa-lg marginrightleft-10"></i>
                          {translate("web.reject", "Reject")}
                        </React.Fragment>
                      }
                      additionalText={`
                        ${translate(
                        "web.rejectBuyerComments",
                        "*To Reject The Request Please Enter The Buyer Comments*"
                      )}
                      `}
                      margin={"0"}
                      width={"260px"}
                      onClick={() => {
                        setOpenPopup((o) => !o);
                      }}
                      disabled={
                        formModel.requestsType === "Portal" &&
                          formModel.buyerComment !== "" &&
                          formModel.buyerComment != null
                          ? false
                          : true
                      }
                    />
                  </div>
                  <div className="my-2 mx-1 text-center nowidthClass">
                    {userProfile.participateWithoutPayment == true && (
                      <ActionButton
                        className="btn-info text-white"
                        type={"bootstrapbtn"}
                        text={`<i class="fa-solid fa-wallet fa-lg marginrightleft-10"></i>                   
                          ${translate("web.approveAndPayLater", "Approve And Pay Later")}
                          `}
                        margin={"0"}
                        width={"260px"}
                        onClick={() =>
                          handelApproveRejectRequest("BuyerApproved", "no")
                        }
                      />
                    )}
                  </div>
                  <div className="my-2 mx-1  nowidthClass">
                    <ActionButton
                      className={"btn-success requestBtn mx-0 w-100"}
                      type={"requests"}
                      label={translate("web.approvePay", "Approve And Pay")}
                      text={
                        <>
                          <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                          {translate("web.approvePay", "Approve And Pay")}
                        </>
                      }
                      margin={"0"}
                      width={"260px"}
                      onClick={() =>
                        handelApproveRejectRequest("BuyerApproved", "yes")
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            ) : (
              // <button
              //   className="btn btn-danger pading-10-20 m-23 width_200"
              //   disabled
              // >
              //   <i className="fa fa-save fa-lg marginrightleft-10"></i>
              //   {translate("web.submit", "Submit")}
              // </button>
              ""
            )}

          </div>
          {
            //pop up for reject
          }
          <Popup
            modal
            lockScroll
            open={openPopup}
            closeOnDocumentClick={false}
            closeOnEscape
            style={{ background: "red" }}
            contentStyle={{ width: "90%", maxWidth: 400 }}
          >
            {(close) => (
              <React.Fragment>
                <div className="">
                  {translate(
                    "web.confirmMsgRejectrequest",
                    "Are you sure to reject request?"
                  )}
                </div>
                <div className="divHorseSelect row rtl">
                  <div
                    className="bidSubmit buttonformshowentrycard linkbtn col-lg-4 col-sm-6 text-center"
                    onClick={closeModal}
                  >
                    {translate("web.close", "Close")}
                  </div>
                  <div className=" col-lg-6 col-sm-6">
                    <button
                      type="button"
                      className="btn btn-danger btn-lg"
                      onClick={() => {
                        setOpenPopup((o) => !o);
                        handelApproveRejectRequest("BuyerRejected");
                      }}
                    >
                      <i className="fa-solid fa-ban fa-lg marginrightleft-10"></i>
                      {translate("web.reject", "Reject")}
                    </button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Popup>
          {
            //pop up for cancel
          }
          <Popup
            modal
            lockScroll
            open={openPopupCancel}
            closeOnDocumentClick={false}
            closeOnEscape
            style={{ background: "red" }}
            contentStyle={{ width: "90%", maxWidth: 400 }}
          >
            {(close) => (
              <React.Fragment>
                <div className="">
                  {translate(
                    "web.confirmMsgCancelRequest",
                    "Are you sure to cancel request?"
                  )}
                </div>
                <div className="divHorseSelect row rtl">
                  <div
                    className="bidSubmit buttonformshowentrycard linkbtn col-lg-4 col-sm-6 text-center"
                    onClick={closeModalCancel}
                  >
                    {translate("web.close", "Close")}
                  </div>
                  <div className=" col-lg-6 col-sm-6">
                    <button
                      type="button"
                      className="btn btn-danger btn-lg"
                      onClick={() => {
                        setOpenPopupCancel((o) => !o);
                        handelCancelRequest();
                      }}
                    >
                      <i className="fa-solid fa-ban fa-lg marginrightleft-10"></i>
                      {translate("web.cancel", "Cancel")}
                    </button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Popup>
        </div>
      </div>
    </div>
  );
}

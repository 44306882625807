import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FetchApproveRejectRequestAPI,
  FetchApproveRejectRequestCoveringCertificateAPI,
  FetchCancelRequestAPI,
  FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekAPI,
  FetchCreateCoveringCertificateServiceRequestAPI,
  FetchCreateEmbryoOocyteTransferPermitServiceRequestAPI,
  FetchCreateOwnerShipTransferServicesRequestsAPI,
  FetchDeleteOwnerShipTransferServicesRequestsAPI,
  FetchGetAllServicesRequestsAPI,
  FetchGetAllServicesRequestsOwnerToApproveAPI,
  FetchGetDocumentTypesByServiceIdAPI,
  FetchGetFileAPI,
  FetchGetFilebase64API,
  FetchGetRegisteredHorsesAPI,
  FetchGetServiceDefinitionByIdAPI,
  FetchGetServicesRequestByIdAPI,
  FetchIsHaseRequestEmbryoOocyteTransferPermitAPI,
  FetchUpdateCoveringCertificateServiceRequestAPI,
  FetchUpdateEmbryoOocyteTransferPermitServiceRequestAPI,
  FetchUpdateOwnerShipTransferServicesRequestsAPI,
} from "./ServicesRequestsApi";

export const FetchCreateOwnerShipTransferServicesRequeststhunk =
  createAsyncThunk(
    "ServicesRequests/FetchCreateOwnerShipTransferServicesRequests",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateOwnerShipTransferServicesRequestsAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchGetAllServicesRequeststhunk = createAsyncThunk(
  "ServicesRequests/FetchGetAllServicesRequests",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetAllServicesRequestsAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetServicesRequestByIdthunk = createAsyncThunk(
  "ServicesRequests/FetchGetServicesRequestById",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetServicesRequestByIdAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchDeleteOwnerShipTransferServicesRequeststhunk =
  createAsyncThunk(
    "ServicesRequests/FetchDeleteOwnerShipTransferServicesRequests",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchDeleteOwnerShipTransferServicesRequestsAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchGetAllServicesRequestsOwnerToApprovethunk = createAsyncThunk(
  "ServicesRequests/FetchGetAllServicesRequestsOwnerToApprove",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetAllServicesRequestsOwnerToApproveAPI(
        param
      );
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchUpdateOwnerShipTransferServicesRequeststhunk =
  createAsyncThunk(
    "ServicesRequests/FetchUpdateOwnerShipTransferServicesRequests",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchUpdateOwnerShipTransferServicesRequestsAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchApproveRejectRequestthunk = createAsyncThunk(
  "ServicesRequests/FetchApproveRejectRequest",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchApproveRejectRequestAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetServiceDefinitionByIdthunk = createAsyncThunk(
  "ServicesRequests/FetchGetServiceDefinitionById",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetServiceDefinitionByIdAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetFilethunk = createAsyncThunk(
  "ServicesRequests/FetchGetFile",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetFileAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetFilebase64thunk = createAsyncThunk(
  "ServicesRequests/FetchGetFilebase64",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetFilebase64API(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetDocumentTypesByServiceIdthunk = createAsyncThunk(
  "ServicesRequests/FetchGetDocumentTypesByServiceId",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetDocumentTypesByServiceIdAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetRegisteredHorsesthunk = createAsyncThunk(
  "ServicesRequests/FetchGetRegisteredHorses",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetRegisteredHorsesAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//covering certificate
export const FetchCreateCoveringCertificateServiceRequestthunk =
  createAsyncThunk(
    "CoveringCertificateServiceRequest/FetchCreateCoveringCertificateServiceRequest",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateCoveringCertificateServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchUpdateCoveringCertificateServiceRequestthunk =
  createAsyncThunk(
    "CoveringCertificateServiceRequest/FetchUpdateCoveringCertificateServiceRequest",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchUpdateCoveringCertificateServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchApproveRejectRequestCoveringCertificatethunk =
  createAsyncThunk(
    "CoveringCertificateServiceRequest/FetchApproveRejectRequestCoveringCertificate",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchApproveRejectRequestCoveringCertificateAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchCancelRequestthunk =
  createAsyncThunk(
    "CoveringCertificateServiceRequest/FetchCancelRequest",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCancelRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk =
  createAsyncThunk(
    "CoveringCertificateServiceRequest/FetchCheckLastChildrenOfHorseMareRegLessThanOneWeek",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//Embryo Oocyte Transfer Permit
export const FetchCreateEmbryoOocyteTransferPermitServiceRequestthunk =
  createAsyncThunk(
    "EmbryoOocyteTransferPermitServiceRequest/FetchCreateEmbryoOocyteTransferPermitServiceRequest",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateEmbryoOocyteTransferPermitServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchUpdateEmbryoOocyteTransferPermitServiceRequestthunk =
  createAsyncThunk(
    "EmbryoOocyteTransferPermitServiceRequest/FetchUpdateEmbryoOocyteTransferPermitServiceRequest",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchUpdateEmbryoOocyteTransferPermitServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchIsHaseRequestEmbryoOocyteTransferPermitthunk =
  createAsyncThunk(
    "EmbryoOocyteTransferPermitServiceRequest/FetchIsHaseRequestEmbryoOocyteTransferPermit",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchIsHaseRequestEmbryoOocyteTransferPermitAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
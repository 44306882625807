import axios from "../../axios/axios";

export const FetchCreateOwnerShipTransferServicesRequestsAPI = async (
  param
) => {
  try {
    const formData = new FormData();
    formData.append("id", param.id);
    formData.append("serviceDefinitionId", param.serviceDefinitionId);
    formData.append("employeeId", param.employeeId);
    formData.append("date", param.date);
    formData.append("status", param.status);
    formData.append("requestDetails.horseId", param.requestDetails.horseId);
    formData.append("requestDetails.ownerId", param.requestDetails.ownerId);
    formData.append(
      "requestDetails.newOwnerId",
      param.requestDetails.newOwnerId
    );
    formData.append(
      "requestDetails.attachmentFile",
      param.requestDetails.attachmentFile
    );
    formData.append(
      "requestDetails.documentTypeId",
      param.requestDetails.documentTypeId
    );
    formData.append("requestDetails.createdBy", param.requestDetails.createdBy);
    formData.append("requestDetails.effectiveDate", param.requestDetails.effectiveDate);
    formData.append("requestDetails.isCollectSemen", param.requestDetails.isCollectSemen);
    formData.append("requestDetails.numberOfDoses", param.requestDetails.numberOfDoses);

    const response = await axios.post(
      `/ServicesRequests/CreateOwnerShipTransferServicesRequests`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetAllServicesRequestsAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetAllServicesRequests?language=${param.language}&userId=${param.userId}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetServicesRequestByIdAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetServicesRequestById?id=${param.id}&language=${param.language}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchDeleteOwnerShipTransferServicesRequestsAPI = async (
  param
) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/DeleteOwnerShipTransferServicesRequests?id=${param}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetAllServicesRequestsOwnerToApproveAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetAllServicesRequestsOwnerToApprove?language=${param.language}&userId=${param.userId}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchUpdateOwnerShipTransferServicesRequestsAPI = async (
  param
) => {
  try {
    const formData = new FormData();
    formData.append("id", param.id);
    formData.append("serviceDefinitionId", param.serviceDefinitionId);
    formData.append("comment", param.comment);
    formData.append("employeeId", param.employeeId);
    formData.append("date", param.date);
    formData.append("status", param.status);
    formData.append("requestDetails.horseId", param.requestDetails.horseId);
    formData.append("requestDetails.ownerId", param.requestDetails.ownerId);
    formData.append(
      "requestDetails.newOwnerId",
      param.requestDetails.newOwnerId
    );
    formData.append(
      "requestDetails.attachmentFile",
      param.requestDetails.attachmentFile
    );
    formData.append(
      "requestDetails.documentTypeId",
      param.requestDetails.documentTypeId
    );
    formData.append("requestDetails.effectiveDate", param.requestDetails.effectiveDate);
    formData.append("requestDetails.address", param.requestDetails.address);
    formData.append("requestDetails.mobile", param.requestDetails.mobile);
    formData.append("requestDetails.receiveType", param.requestDetails.receiveType);
    formData.append("requestDetails.isCollectSemen", param.requestDetails.isCollectSemen);
    formData.append("requestDetails.numberOfDoses", param.requestDetails.numberOfDoses);

    const response = await axios.post(
      `/ServicesRequests/UpdateOwnerShipTransferServicesRequests`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchApproveRejectRequestAPI = async (param) => {
  try {
    let response = await axios.post(`/ServicesRequests/ApproveRejectRequest`, {
      id: param.id,
      employeeId: param.employeeId,
      status: param.status,
      comment: param.comment,
      address: param.address,
      mobile: param.mobile,
      receiveType: param.receiveType,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetServiceDefinitionByIdAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetServiceDefinitionById?id=${param}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetFileAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetFile?filePath=${param.filePath}&fileType=${param.Type}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetFilebase64API = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetFilebase64?filePath=${param}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetDocumentTypesByServiceIdAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetDocumentTypesByServiceId?id=${param.id}&lang=${param.language}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetRegisteredHorsesAPI = async (param) => {
  try {
    let response = await axios.post(`/ServicesRequests/GetRegisteredHorses`, {
      searchValue: param.searchValue,
      lang: param.lang,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//covering certificate
export const FetchCreateCoveringCertificateServiceRequestAPI = async (
  param
) => {
  try {
    const formData = new FormData();
    formData.append("serviceDefinitionId", param.serviceDefinitionId);
    formData.append("date", param.date);
    formData.append("status", param.status);

    formData.append("isStallionRegistered", param.isStallionRegistered);
    formData.append("notStallionOwner", param.notStallionOwner);
    formData.append("horseStallion", param.horseStallion);
    formData.append("horseNameEnStallionUnReg", param.horseNameEnStallionUnReg);
    formData.append("horseNameArStallionUnReg", param.horseNameArStallionUnReg);
    formData.append("damEnStallionUnReg", param.damEnStallionUnReg);
    formData.append("damArStallionUnReg", param.damArStallionUnReg);
    formData.append("sireEnStallionUnReg", param.sireEnStallionUnReg);
    formData.append("sireArStallionUnReg", param.sireArStallionUnReg);
    formData.append("ownerNameEnStallionUnReg", param.ownerNameEnStallionUnReg);
    formData.append("ownerNameArStallionUnReg", param.ownerNameArStallionUnReg);
    formData.append("breederNameEnStallionUnReg", param.breederNameEnStallionUnReg);
    formData.append("breederNameArStallionUnReg", param.breederNameArStallionUnReg);
    formData.append("horseAddressStallionUnReg", param.horseAddressStallionUnReg);
    formData.append("currentRegNoStallionUnReg", param.currentRegNoStallionUnReg);
    formData.append("uelnStallionUnReg", param.uelnStallionUnReg);
    formData.append("colorStallionUnReg", param.colorStallionUnReg);
    formData.append("originStallionUnReg", param.originStallionUnReg);
    formData.append("horseCountryStallionUnReg", param.horseCountryStallionUnReg);
    formData.append("birthDateStallionUnRegString", param.birthDateStallionUnReg);
    formData.append("isMareRegistered", param.isMareRegistered);
    formData.append("horseMareReg", param.horseMareReg);
    formData.append("horseNameEnMareUnReg", param.horseNameEnMareUnReg);
    formData.append("horseNameArMareUnReg", param.horseNameArMareUnReg);
    formData.append("damEnMareUnReg", param.damEnMareUnReg);
    formData.append("damArMareUnReg", param.damArMareUnReg);
    formData.append("sireEnMareUnReg", param.sireEnMareUnReg);
    formData.append("sireArMareUnReg", param.sireArMareUnReg);
    formData.append("breederNameEnMareUnReg", param.breederNameEnMareUnReg);
    formData.append("breederNameArMareUnReg", param.breederNameArMareUnReg);
    formData.append("horseAddressMareUnReg", param.horseAddressMareUnReg);
    formData.append("currentRegNoMareUnReg", param.currentRegNoMareUnReg);
    formData.append("uelnMareUnReg", param.uelnMareUnReg);
    formData.append("colorMareUnReg", param.colorMareUnReg);
    formData.append("originMareUnReg", param.originMareUnReg);
    formData.append("horseCountryMareUnReg", param.horseCountryMareUnReg);
    formData.append("birthDateMareUnRegString", param.birthDateMareUnReg);
    formData.append("typeOfCovering", param.typeOfCovering);
    formData.append("coveringDateString", param.coveringDate);
    formData.append("breedingHappendAbroad", param.breedingHappendAbroad);
    formData.append("embryoIsImported", param.embryoIsImported);
    formData.append("stallionOwnerSignedPermit", param.stallionOwnerSignedPermit);
    formData.append("breedingHappendAbroadAttachmentFile", param.breedingHappendAbroadAttachmentFile);
    formData.append("embryoIsImportedAttachmentFile", param.embryoIsImportedAttachmentFile);
    formData.append("stallionOwnerSignedPermitAttachmentFile", param.stallionOwnerSignedPermitAttachmentFile);
    formData.append("importedSemenCertificateAttachmentFile", param.importedSemenCertificateAttachmentFile);
    formData.append("ownerIdNotStallionReg", param.ownerIdNotStallionReg);
    formData.append("referenceNumberSemenCollectionCertificate", param.referenceNumberSemenCollectionCertificate);
    formData.append("ownerId", param.ownerId);
    formData.append("createdBy", param.createdBy);

    const response = await axios.post(
      `/CoveringCertificateServiceRequest/CreateCoveringCertificateServiceRequest`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchUpdateCoveringCertificateServiceRequestAPI = async (
  param
) => {
  try {
    const formData = new FormData();
    formData.append("id", param.id);
    formData.append("serviceDefinitionId", param.serviceDefinitionId);
    formData.append("date", param.date);
    formData.append("status", param.status);
    formData.append("coveringCertificateId", param.coveringCertificateId);

    formData.append("isStallionRegistered", param.isStallionRegistered);
    formData.append("notStallionOwner", param.notStallionOwner);
    formData.append("horseStallion", param.horseStallion);
    formData.append("horseNameEnStallionUnReg", param.horseNameEnStallionUnReg);
    formData.append("horseNameArStallionUnReg", param.horseNameArStallionUnReg);
    formData.append("damEnStallionUnReg", param.damEnStallionUnReg);
    formData.append("damArStallionUnReg", param.damArStallionUnReg);
    formData.append("sireEnStallionUnReg", param.sireEnStallionUnReg);
    formData.append("sireArStallionUnReg", param.sireArStallionUnReg);
    formData.append("ownerNameEnStallionUnReg", param.ownerNameEnStallionUnReg);
    formData.append("ownerNameArStallionUnReg", param.ownerNameArStallionUnReg);
    formData.append("breederNameEnStallionUnReg", param.breederNameEnStallionUnReg);
    formData.append("breederNameArStallionUnReg", param.breederNameArStallionUnReg);
    formData.append("horseAddressStallionUnReg", param.horseAddressStallionUnReg);
    formData.append("currentRegNoStallionUnReg", param.currentRegNoStallionUnReg);
    formData.append("uelnStallionUnReg", param.uelnStallionUnReg);
    formData.append("colorStallionUnReg", param.colorStallionUnReg);
    formData.append("originStallionUnReg", param.originStallionUnReg);
    formData.append("horseCountryStallionUnReg", param.horseCountryStallionUnReg);
    formData.append("birthDateStallionUnRegString", param.birthDateStallionUnReg);
    formData.append("isMareRegistered", param.isMareRegistered);
    formData.append("horseMareReg", param.horseMareReg);
    formData.append("horseNameEnMareUnReg", param.horseNameEnMareUnReg);
    formData.append("horseNameArMareUnReg", param.horseNameArMareUnReg);
    formData.append("damEnMareUnReg", param.damEnMareUnReg);
    formData.append("damArMareUnReg", param.damArMareUnReg);
    formData.append("sireEnMareUnReg", param.sireEnMareUnReg);
    formData.append("sireArMareUnReg", param.sireArMareUnReg);
    formData.append("breederNameEnMareUnReg", param.breederNameEnMareUnReg);
    formData.append("breederNameArMareUnReg", param.breederNameArMareUnReg);
    formData.append("horseAddressMareUnReg", param.horseAddressMareUnReg);
    formData.append("currentRegNoMareUnReg", param.currentRegNoMareUnReg);
    formData.append("uelnMareUnReg", param.uelnMareUnReg);
    formData.append("colorMareUnReg", param.colorMareUnReg);
    formData.append("originMareUnReg", param.originMareUnReg);
    formData.append("horseCountryMareUnReg", param.horseCountryMareUnReg);
    formData.append("birthDateMareUnRegString", param.birthDateMareUnReg);
    formData.append("typeOfCovering", param.typeOfCovering);
    formData.append("coveringDateString", param.coveringDate);
    formData.append("breedingHappendAbroad", param.breedingHappendAbroad);
    formData.append("embryoIsImported", param.embryoIsImported);
    formData.append("stallionOwnerSignedPermit", param.stallionOwnerSignedPermit);
    formData.append("breedingHappendAbroadAttachmentFile", param.breedingHappendAbroadAttachmentFile);
    formData.append("embryoIsImportedAttachmentFile", param.embryoIsImportedAttachmentFile);
    formData.append("stallionOwnerSignedPermitAttachmentFile", param.stallionOwnerSignedPermitAttachmentFile);
    formData.append("importedSemenCertificateAttachmentFile", param.importedSemenCertificateAttachmentFile);
    formData.append("ownerIdNotStallionReg", param.ownerIdNotStallionReg);
    formData.append("referenceNumberSemenCollectionCertificate", param.referenceNumberSemenCollectionCertificate);
    formData.append("ownerId", param.ownerId);
    formData.append("createdBy", param.createdBy);
    formData.append("createdBy", param.eventName);

    const response = await axios.post(
      `/CoveringCertificateServiceRequest/UpdateCoveringCertificateServiceRequest`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchApproveRejectRequestCoveringCertificateAPI = async (param) => {
  try {
    let response = await axios.post(`/CoveringCertificateServiceRequest/ApproveRejectRequestCoveringCertificate`, {
      id: param.id,
      status: param.status,
      comment: param.comment,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchCancelRequestAPI = async (param) => {
  try {
    let response = await axios.get(`/CoveringCertificateServiceRequest/CancelRequest?serviceId=${param.serviceId}&ownerId=${param.ownerId}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekAPI = async (param) => {
  try {
    let response = await axios.get(`/CoveringCertificateServiceRequest/CheckLastChildrenOfHorseMareRegLessThanOneWeek?horseId=${param.horseId}&coveringDate=${param.coveringDate}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//Embryo Oocyte Transfer Permit
export const FetchCreateEmbryoOocyteTransferPermitServiceRequestAPI = async (param) => {
  try {
    let response = await axios.post(`/EmbryoOocyteTransferPermitServiceRequest/CreateEmbryoOocyteTransferPermitServiceRequest`, {
      serviceDefinitionId: param.serviceDefinitionId,
      //date: param.date,
      status: param.status,
      eventName: param.eventName,
      horse: param.horse,
      ownerId: param.ownerId,
      createdBy: param.createdBy,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchUpdateEmbryoOocyteTransferPermitServiceRequestAPI = async (param) => {
  try {
    let response = await axios.post(`/EmbryoOocyteTransferPermitServiceRequest/UpdateEmbryoOocyteTransferPermitServiceRequest`, {
      id: param.id,
      serviceDefinitionId: param.serviceDefinitionId,
      //date: param.date,
      status: param.status,
      eventName: param.eventName,
      horse: param.horse,
      ownerId: param.ownerId,
      createdBy: param.createdBy,
      embryoOocyteTransferPermitId: param.embryoOocyteTransferPermitId
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchIsHaseRequestEmbryoOocyteTransferPermitAPI = async (param) => {
  try {
    let response = await axios.get(`/EmbryoOocyteTransferPermitServiceRequest/IsHaseRequestEmbryoOocyteTransferPermit?horseId=${param.horseId}&serviceDefinitionId=${param.serviceDefinitionId}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};
